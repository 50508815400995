import { Connection, PublicKey } from "@solana/web3.js";
import { getMint } from "@solana/spl-token";
import { tokenABI } from "default-variables";
import { ethers } from "ethers";
import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
    useTransition,
} from "react";
import {
    getEvmTokenAllowance,
    getEvmTokenBalance,
    getSolTokenAllowance,
    getSolTokenBalance,
} from "utils/tokens";

/* Specific state for loading given data */
export enum WalletDataState {
    IDLE = "idle",
    LOADING = "loading",
    LOADED = "loaded",
    ERROR = "error",
}

interface TokenAllowance {
    amount: number;
    state: WalletDataState;
}

interface TokenBalance {
    amount: number;
    state: WalletDataState;
}

enum TokenDataType {
    Allowance = "allowance",
    Balance = "balance",
}

interface TokenData {
    [TokenDataType.Allowance]: TokenAllowance;
    [TokenDataType.Balance]: TokenBalance;
}

interface NetworkData {
    [tokenAddress: string]: TokenData;
}

interface WalletData {
    [networkId: number]: NetworkData;
}

export interface LoadWalletData {
    walletAddress: string;
    tokenAddress: string;
    networkId: number;
    type: TokenDataType;
    dataFetcher: () => Promise<number | false>;
}

export interface LoadWalletAllowance {
    walletAddress: string;
    tokenAddress: string;
    networkId: number;
    contractAddress: string;
    rpcUrl: string;
}

export interface LoadWalletBalance {
    walletAddress: string;
    tokenAddress: string;
    networkId: number;
    rpcUrl: string;
}

export interface GetTokenData {
    walletAddress: string;
    tokenAddress: string;
    networkId: number;
}

interface WalletInformationContextType {
    loadWalletBalance: (params: LoadWalletBalance) => void;
    loadWalletAllowance: (params: LoadWalletAllowance) => void;
    getTokenData: (params: GetTokenData) => TokenData;
    walletData: Map<string, WalletData>;
}

const WalletInformationContext =
    createContext<WalletInformationContextType | undefined>(undefined);

interface WalletInformationProviderProps {
    children: ReactNode;
}

// [ ] We don't need this context provider, not even sure why it was created in the first place - THIS NEEDS TO GO
// [ ] Also look at `useBalanceAndAllowance` and determine if it's necessary

// This is basically a provider we can wrap around any app that will keep get, set and batch get
// allowance and balances for any given address on any given network, a provider will
// help us avoid duplicating queries in the long run.

// This is meant as a one-stop shop to get balance and allowances for a given wallet
// in order to avoid query duplication, and possibly batch fetch in the future
export const WalletInformationProvider: React.FC<WalletInformationProviderProps> =
    ({ children }) => {
        // Map of walletAddress => Wallet data
        const walletDataRef = useRef<Map<string, WalletData>>(new Map());
        const [walletData, setWalletData] = useState<Map<string, WalletData>>(
            new Map()
        );
        const [_isPending, startTransition] = useTransition();

        const defaultTokenData: TokenData = useMemo(() => {
            return {
                allowance: {
                    amount: 0,
                    state: WalletDataState.IDLE,
                },
                balance: {
                    amount: 0,
                    state: WalletDataState.IDLE,
                },
            };
        }, []);

        const getAddressAllowance = useCallback(
            async (
                tokenAddress: string,
                address: string,
                rpcUrl: string,
                contractAddress: string,
                networkId: number
            ) => {
                if (!tokenAddress || !address) return false;

                let decimals, allowance;
                try {
                    // This is not the prettiest but it works for now until this component is refactored
                    if ([900, 901, 902].includes(networkId)) {
                        const provider = new Connection(rpcUrl, "confirmed");
                        const { decimals: mintDecimals } = await getMint(
                            provider,
                            new PublicKey(tokenAddress)
                        );

                        decimals = mintDecimals;
                        allowance = await getSolTokenAllowance({
                            tokenAddress,
                            walletAddress: address,
                            provider,
                            contractAddress,
                        });
                    } else {
                        const provider = new ethers.providers.JsonRpcProvider(
                            rpcUrl
                        );
                        const erc20 = new ethers.Contract(
                            tokenAddress,
                            tokenABI,
                            provider
                        );

                        decimals = await erc20.decimals();
                        allowance = await getEvmTokenAllowance({
                            tokenAddress,
                            walletAddress: address,
                            provider: new ethers.providers.JsonRpcProvider(
                                rpcUrl
                            ),
                            contractAddress,
                        });
                    }
                } catch (error) {
                    console.error(error);
                    return 0;
                }

                return Number(ethers.utils.formatUnits(allowance, decimals));
            },
            []
        );

        const getWalletData = useCallback((walletAddress: string) => {
            return walletDataRef.current.get(walletAddress);
        }, []);

        const getTokenData = useCallback(
            ({ walletAddress, networkId, tokenAddress }: GetTokenData) => {
                const currentWalletData = getWalletData(walletAddress) || {};
                const currentNetworkData = currentWalletData[networkId] || {};
                const currentTokenData =
                    currentNetworkData[tokenAddress] || defaultTokenData;
                return currentTokenData;
            },
            [defaultTokenData, getWalletData]
        );

        const updateWalletData = useCallback(
            (walletAddress: string, walletData: WalletData) => {
                startTransition(() => {
                    walletDataRef.current.set(walletAddress, walletData);
                    setWalletData((prevMap) =>
                        new Map(prevMap).set(walletAddress, walletData)
                    );
                });
            },
            []
        );

        const updateTokenData = useCallback(
            (
                walletAddress: string,
                tokenAddress: string,
                networkId: number,
                type: TokenDataType,
                data: TokenAllowance | TokenBalance
            ) => {
                const currentWalletData = getWalletData(walletAddress) || {};
                const currentNetworkData = currentWalletData[networkId] || {};
                const currentTokenData =
                    currentNetworkData[tokenAddress] ||
                    getTokenData({ walletAddress, networkId, tokenAddress });

                const updatedWalletData: WalletData = {
                    ...currentWalletData,
                    [networkId]: {
                        ...currentNetworkData,
                        [tokenAddress]: {
                            ...currentTokenData,
                            [type]: data,
                        },
                    },
                };

                updateWalletData(walletAddress, updatedWalletData);
            },
            [getTokenData, getWalletData, updateWalletData]
        );

        // Generic amount loader
        const loadWalletData = useCallback(
            async ({
                walletAddress,
                tokenAddress,
                networkId,
                type,
                dataFetcher,
            }: LoadWalletData) => {
                const tokenData = getTokenData({
                    walletAddress,
                    networkId,
                    tokenAddress,
                });

                if (tokenData[type].state === WalletDataState.LOADED) {
                    return;
                }

                if (tokenData[type].state === WalletDataState.LOADING) {
                    return;
                }

                updateTokenData(walletAddress, tokenAddress, networkId, type, {
                    amount: 0,
                    state: WalletDataState.LOADING,
                });

                try {
                    const amount = await dataFetcher();

                    if (typeof amount === "number") {
                        updateTokenData(
                            walletAddress,
                            tokenAddress,
                            networkId,
                            type,
                            {
                                amount: amount,
                                state: WalletDataState.LOADED,
                            }
                        );
                    }
                } catch (error) {
                    updateTokenData(
                        walletAddress,
                        tokenAddress,
                        networkId,
                        type,
                        {
                            amount: 0,
                            state: WalletDataState.ERROR,
                        }
                    );
                }
            },
            [getTokenData, updateTokenData]
        );

        const loadWalletAllowance = useCallback(
            async ({
                walletAddress,
                tokenAddress,
                networkId,
                contractAddress,
                rpcUrl,
            }: LoadWalletAllowance) => {
                await loadWalletData({
                    walletAddress,
                    tokenAddress,
                    networkId,
                    type: TokenDataType.Allowance,
                    dataFetcher: async () =>
                        await getAddressAllowance(
                            tokenAddress,
                            walletAddress,
                            rpcUrl,
                            contractAddress,
                            networkId
                        ),
                });
            },
            [getAddressAllowance, loadWalletData]
        );

        const loadWalletBalance = useCallback(
            async ({
                walletAddress,
                tokenAddress,
                networkId,
                rpcUrl,
            }: LoadWalletBalance) => {
                // This is just a quick fix to get things working - this whole component will be refactored to use the new wallet context
                let balanceFn;
                if ([900, 901, 902].includes(networkId))
                    balanceFn = getSolTokenBalance({
                        tokenAddress,
                        walletAddress,
                        provider: new Connection(rpcUrl, "confirmed"),
                    });
                else
                    balanceFn = getEvmTokenBalance({
                        tokenAddress,
                        walletAddress,
                        provider: new ethers.providers.JsonRpcProvider(rpcUrl),
                    });

                await loadWalletData({
                    walletAddress,
                    tokenAddress,
                    networkId,
                    type: TokenDataType.Balance,
                    dataFetcher: async () => await balanceFn,
                });
            },
            [getEvmTokenBalance, getSolTokenBalance, loadWalletData]
        );

        const value = {
            loadWalletBalance,
            loadWalletAllowance,
            getTokenData,
            walletData,
        };

        return (
            <WalletInformationContext.Provider value={value}>
                {children}
            </WalletInformationContext.Provider>
        );
    };

export const useWalletInformationProvider =
    (): WalletInformationContextType => {
        const context = useContext(WalletInformationContext);
        if (!context) {
            throw new Error(
                "useWalletInformationProvider must be used within a WalletInformationProvider"
            );
        }
        return context;
    };
