import * as S from "./style";
import { memo, useCallback, useEffect } from "react";
import { Company } from "company/types";
import { GeneralTokenDetailsResponse } from "api";
import Button, { ButtonSizes, ButtonVariants } from "components/Button";
import TokenAllowance from "components/TokenAllowance";
import { useBalanceAndAllowance } from "hooks/useBalanceAndAllowance";
import {
    WalletDataState,
    useWalletInformationProvider,
} from "contexts/WalletInformationProvider";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { SmartContractType } from "types/common-enums";
import { useWalletContext } from "context/WalletContext";

interface BalanceAndAllowanceCellProps {
    token: GeneralTokenDetailsResponse;
    walletAddress: string;
}

const BalanceAndAllowanceCell = memo(
    ({ token, walletAddress }: BalanceAndAllowanceCellProps) => {
        const { loadBalanceAndAllowance } = useBalanceAndAllowance();
        const { walletConnected } = useWalletContext();

        const { getTokenData } = useWalletInformationProvider();

        const {
            config: { contracts },
        } = useGetCompanyConfig();

        const contract = contracts.find(
            (c: Company.Contract) =>
                c.contractType === SmartContractType.VariableRate &&
                c.networkId === token.networkId
        );

        const currentWalletData = getTokenData({
            walletAddress: walletAddress,
            tokenAddress: token.address,
            networkId: token.networkId,
        });

        const { allowance, balance } = currentWalletData;

        const isLoaded =
            allowance.state === WalletDataState.LOADED &&
            balance.state === WalletDataState.LOADED;

        const isIdle =
            allowance.state === WalletDataState.IDLE &&
            balance.state === WalletDataState.IDLE;

        const senderWalletIsConnectedWallet =
            walletConnected &&
            walletAddress.toLocaleLowerCase() ===
                walletConnected?.address.toLocaleLowerCase()
                ? true
                : false;

        const handleClick = useCallback(async () => {
            await loadBalanceAndAllowance(
                walletAddress,
                token.address,
                token.networkId
            );
        }, [
            walletAddress,
            loadBalanceAndAllowance,
            token.address,
            token.networkId,
        ]);

        const ownerIsConnected =
            walletConnected &&
            walletAddress.toLocaleLowerCase() ===
                walletConnected.address.toLocaleLowerCase()
                ? true
                : false;

        useEffect(() => {
            if (!ownerIsConnected) return;
            (async () => {
                await loadBalanceAndAllowance(
                    walletAddress,
                    token.address,
                    token.networkId
                );
            })();
        }, [
            isLoaded,
            loadBalanceAndAllowance,
            ownerIsConnected,
            token.address,
            token.networkId,
            walletAddress,
        ]);

        if (!contract) return <>No Contract</>;

        if (isLoaded) {
            return (
                <S.DefinitionList>
                    <div>
                        <dt>Allowance:</dt>{" "}
                        <dd>
                            {senderWalletIsConnectedWallet ? (
                                <TokenAllowance
                                    token={token}
                                    contract={contract.address}
                                    icons
                                />
                            ) : (
                                <>
                                    <span>{token.symbol}</span>
                                    <span>{allowance.amount}</span>
                                </>
                            )}
                        </dd>
                    </div>
                    <div>
                        <dt>Balance:</dt>{" "}
                        <dd>
                            <span>{token.symbol}</span>
                            <span>{balance.amount}</span>
                        </dd>
                    </div>
                </S.DefinitionList>
            );
        }

        return (
            <>
                <Button
                    variant={ButtonVariants.NeutralOutlined}
                    size={ButtonSizes.Small}
                    onClick={handleClick}
                    loading={!isIdle}
                >
                    Get Data
                </Button>
            </>
        );
    }
);
export default BalanceAndAllowanceCell;
