import * as S from "./style";
import { useEffect, useState } from "react";
import { useSession } from "context/Session";
import { useNotificationQueue } from "context/NotificationQueue";
import Logo from "components/Logo";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import Anchor from "components/Anchor";
import Button from "components/Button";
import Pencil from "components/icons/Pencil";
import Tooltip from "components/Tooltip";
import WalletConnectMultiSig from "components/WalletConnectMultiSig";
import { NotificationType } from "components/Notification";
import { reactAppBuildType } from "default-variables";
import { useWalletContext } from "context/WalletContext";

const LoginWeb3 = ({ ...props }) => {
    const {
        handleConnectWallet,
        isWalletConnecting,
        walletConnected,
        networkConnected,
        getSignedMessage,
        verifySignedMessage,
    } = useWalletContext();
    const { setWeb3Session, getSessionToken, killSession } = useSession();
    const { addNotification } = useNotificationQueue();
    const [awaitingSignature, setAwaitingSignature] = useState<string>(``);

    useEffect(() => {
        if (!walletConnected) {
            setAwaitingSignature(``);
            return;
        }

        const token = getSessionToken();

        if (token) {
            verifySession(token);
        } else if (awaitingSignature !== walletConnected.address) {
            signWalletMessage(walletConnected.address);
        }
    }, [walletConnected]);

    const verifySession = async (token: string) => {
        if (!walletConnected) return;

        try {
            if (verifySignedMessage(token)) {
                // Reset expiry and redirect
                setWeb3Session(token, walletConnected.address, [
                    /* roles will go here */
                ]);
            } else {
                // Will clear cookies and redirect back to `/login` again
                // NOTE: If a user switches wallet addresses, they will have to re-sign to switch back
                killSession();
            }
        } catch (error) {
            killSession();

            // If token doesn't verify, just kill the session, do we really need to present a popup?
            addNotification({
                msg: (
                    <>
                        Something went wrong during login.
                        <br />
                        Let's give it another try.
                    </>
                ),
                type: NotificationType.ERROR,
            });
        }
    };

    const signWalletMessage = async (address: string) => {
        try {
            if (!walletConnected) throw new Error(`No wallet connected`);

            setAwaitingSignature(address);
            const signerToken = await getSignedMessage();
            if (signerToken)
                setWeb3Session(
                    signerToken?.signature,
                    walletConnected.address,
                    [
                        /* roles will go here */
                    ]
                );
            else throw new Error(`No signer token generated`);
        } catch (error) {
            addNotification({
                msg: `The login message was not signed`,
                type: NotificationType.ERROR,
            });
        } finally {
            setAwaitingSignature(``);
        }
    };

    const handleConnectClick = () => {
        if (!walletConnected) {
            handleConnectWallet();
        } else if (!getSessionToken()) {
            signWalletMessage(walletConnected.address);
        }
    };

    return (
        <S.Login gradient environment={reactAppBuildType} {...props}>
            <S.Logo>
                <a href={import.meta.env.VITE_LOOP_WEBSITE}>
                    <img
                        src="/img/loop-logo/loop-crypto-tall-white.svg"
                        alt="Loop Crypto"
                    />
                </a>
            </S.Logo>
            <S.Middle>
                <S.Prompt>
                    <header>
                        <Logo tall lg />
                        <S.EnvBadge />
                        <h1>Welcome Back</h1>
                        {!walletConnected ? (
                            <p>
                                Connect your wallet, then sign a message to
                                manage your automations.
                            </p>
                        ) : (
                            <p>
                                Sign the message in your wallet to begin
                                managing your automations.
                            </p>
                        )}
                    </header>

                    <S.Connect>
                        {walletConnected && networkConnected && (
                            <S.Wallet>
                                <S.Connected>Connected</S.Connected>
                                <S.Address>
                                    {walletConnected?.icon && (
                                        <S.WalletIcon
                                            src={walletConnected.icon}
                                            alt={`${walletConnected.label} logo`}
                                            title={walletConnected.label}
                                        />
                                    )}
                                    {` `}
                                    <DynamicAddressDisplay
                                        address={walletConnected.address}
                                        networkId={networkConnected.networkId}
                                        monospace
                                        shorten
                                    />
                                    <S.Network>
                                        Connected on {networkConnected.label}
                                    </S.Network>
                                </S.Address>
                                <S.EditWallet>
                                    <Tooltip title="Change the connected wallet">
                                        <Button
                                            noStyles
                                            onClick={() =>
                                                handleConnectWallet()
                                            }
                                        >
                                            <Pencil
                                                height="1rem"
                                                fill="#867BF9"
                                            />
                                        </Button>
                                    </Tooltip>
                                </S.EditWallet>
                            </S.Wallet>
                        )}
                        <WalletConnectMultiSig />
                    </S.Connect>

                    <S.Terms>
                        <S.ConnectBtn
                            type="button"
                            title="Connect wallet"
                            onClick={handleConnectClick}
                            disabled={isWalletConnecting || !!awaitingSignature}
                        >
                            {isWalletConnecting
                                ? "Connecting..."
                                : walletConnected && awaitingSignature
                                ? "Awaiting signature..."
                                : walletConnected
                                ? "Sign in with your wallet"
                                : "Connect wallet"}
                        </S.ConnectBtn>
                        <p>
                            By connecting your wallet, you agree to our{" "}
                            <Anchor
                                href={import.meta.env.VITE_TERMS_OF_SERVICE}
                            >
                                Terms of service
                            </Anchor>
                            .
                        </p>
                    </S.Terms>
                </S.Prompt>
            </S.Middle>
        </S.Login>
    );
};

export default LoginWeb3;
