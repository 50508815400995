import { useCallback, useEffect, useState } from "react";
import { getCheckoutWalletAddressStatus } from "api";
import { CheckoutNetworkResponse } from "api/types/checkout";
import { useWalletContext } from "context/WalletContext";

export interface PreviouslySubscribed {
    isSubscribed: boolean;
    networks: number[];
}

const useCheckIfSubscribed = (
    contractAddress: string,
    itemId: string,
    network?: CheckoutNetworkResponse
) => {
    const { walletConnected } = useWalletContext();
    const [subscribed, setSubscribed] =
        useState<PreviouslySubscribed | false>(false);
    const [data, setData] = useState<GetPendingCheckoutStatusResponse>();
    const [, setError] = useState<string>("");
    const [, setSuccess] = useState<boolean>(false);
    const [, setLoading] = useState<boolean>(false);

    const fetchCheckoutWalletAddressStatus = useCallback(
        async (
            networkHexId: string,
            contractAddress: string,
            address: string,
            itemId: string
        ) => {
            setLoading(true);
            try {
                const { data, response } = await getCheckoutWalletAddressStatus(
                    networkHexId,
                    contractAddress,
                    address,
                    itemId
                );

                if (response.ok && data) {
                    setData(data);
                    setSuccess(true);
                }

                if (!response.ok) {
                    setError(
                        "We're having problem communicating with the server"
                    );
                }
            } catch (error) {
                setSuccess(false);
                setError("We're having problem communicating with the server");
            }
            setLoading(false);
        },
        []
    );

    useEffect(() => {
        if (!network) return;
        if (!walletConnected?.address) return;
        if (!itemId) return;
        fetchCheckoutWalletAddressStatus(
            network.hexId,
            contractAddress,
            walletConnected?.proxyFor || walletConnected?.address,
            itemId
        );
    }, [
        contractAddress,
        fetchCheckoutWalletAddressStatus,
        itemId,
        network,
        walletConnected?.address,
        walletConnected?.proxyFor,
    ]);

    useEffect(() => {
        if (!data || !data.existingAgreement) {
            setSubscribed(false);
            return;
        }
        setSubscribed({
            isSubscribed: data.existingAgreement,
            networks: [...data.networkIds],
        });
    }, [data]);

    return { subscribed };
};

export { useCheckIfSubscribed };
