import { AvailableNetwork } from "default-variables";
import { useState, useCallback } from "react";
import {
    EvmWallet,
    PrimaryWalletAccount,
    SolWallet,
} from "./useWalletConnected";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import {
    getEvmTokenAllowance,
    getSolTokenAllowance,
    setEvmTokenAllowance,
    setSolTokenAllowance,
} from "utils/tokens";
import { BigNumber, ethers } from "ethers";
import { Connection } from "@solana/web3.js";
import { EvmProvider, SolProvider } from "types/common";
import { strNumToDecimalPrecision } from "utils/financial";
import useWalletBalance from "./useWalletBalance";

export interface WalletTokenAllowance {
    networkId: string; // hex
    wallet: string;
    token: string;
    contract: string;
    allowance: number | false;
    updatedAt: number;
}

export interface GetTokenAllowanceProps {
    tokenAddress: string;
    contractAddress: string;
    walletAddress?: string;
    networkId?: string;
    // force?: boolean;
}

export interface SetTokenAllowanceProps {
    contractAddress: string;
    tokenAddress: string;
    awaitConfirm?: boolean;
    amount: string | number | BigNumber;
    decimals: number;
}

const useWalletAllowance = (
    connectedWallet: PrimaryWalletAccount | null,
    connectedNetwork: AvailableNetwork | null
) => {
    // [ ] Store on a delay timer
    // const [allowances, setAllowances] = useState<WalletTokenAllowance[]>([]);
    const { getNetworkById } = useAvailableNetworks();

    // [ ] Should these all return BigNumber going forward?
    const getTokenAllowance = useCallback(
        async ({
            // force = false, // For the delay timer
            tokenAddress,
            contractAddress,
            walletAddress,
            networkId,
        }: GetTokenAllowanceProps): Promise<BigNumber> => {
            try {
                if (!walletAddress) {
                    if (!connectedWallet) {
                        throw new Error(
                            `Wallet is not ready for contract interaction`
                        );
                    }

                    walletAddress =
                        connectedWallet.proxyFor || connectedWallet.address;
                }

                if (!networkId) {
                    if (!connectedNetwork || !connectedWallet) {
                        throw new Error(
                            `Wallet is not connected to a contract network`
                        );
                    }
                    // Use the current network
                    networkId = connectedNetwork.networkId;
                }

                const network = getNetworkById(networkId);
                if (!network) {
                    throw new Error(`Network ${networkId} is not available`);
                }

                let chainAndProvider: Pick<
                    PrimaryWalletAccount,
                    "chain" | "provider"
                >;

                // If the network is the same as the connected wallet, use the existing provider
                if (connectedWallet && network.chain !== connectedWallet.chain)
                    chainAndProvider = {
                        chain: connectedWallet.chain,
                        provider: connectedWallet.provider,
                    };
                else if (network.chain === "SOL")
                    chainAndProvider = {
                        chain: "SOL",
                        provider: new Connection(network.rpcUrl, "confirmed"),
                    };
                else
                    chainAndProvider = {
                        chain: "EVM",
                        provider: new ethers.providers.JsonRpcProvider(
                            network.rpcUrl
                        ),
                    };

                let allowance;
                if (chainAndProvider.chain === "SOL") {
                    allowance = await getSolTokenAllowance({
                        provider: chainAndProvider.provider as SolProvider,
                        walletAddress,
                        contractAddress,
                        tokenAddress,
                    });
                } else {
                    allowance = await getEvmTokenAllowance({
                        provider: chainAndProvider.provider as EvmProvider,
                        walletAddress,
                        contractAddress,
                        tokenAddress,
                    });
                }

                return allowance;
            } catch (error) {
                console.error(`Failed to get token allowance: ${error}`);
                return BigNumber.from("0");
            }
        },
        [connectedNetwork, connectedWallet, getNetworkById]
    );

    const setTokenAllowance = useCallback(
        async ({
            contractAddress,
            tokenAddress,
            amount,
            decimals,
            awaitConfirm = false, // confirm tx amount before return
        }: SetTokenAllowanceProps) => {
            try {
                if (!connectedWallet) {
                    throw new Error(`Wallet is not connected`);
                }

                const allowanceAmount = BigNumber.isBigNumber(amount)
                    ? amount
                    : ethers.utils.parseUnits(
                          strNumToDecimalPrecision(String(amount), decimals),
                          decimals
                      );

                let allowance;
                if (connectedWallet.chain === "SOL") {
                    allowance = await setSolTokenAllowance({
                        provider: connectedWallet.provider,
                        signer: connectedWallet.signer,
                        walletAddress: connectedWallet.address,
                        contractAddress,
                        tokenAddress,
                        amount: allowanceAmount,
                        decimals: decimals,
                        awaitConfirm: awaitConfirm,
                    });
                } else {
                    allowance = await setEvmTokenAllowance({
                        signer: connectedWallet.signer,
                        contractAddress,
                        tokenAddress,
                        amount: allowanceAmount,
                        awaitConfirm: awaitConfirm,
                    });
                }

                return allowance;
            } catch (error) {
                console.error(`Failed to set token allowance: ${error}`);
                return null;
            }
        },
        [connectedWallet]
    );

    const addToTokenAllowance = useCallback(
        async ({
            contractAddress,
            tokenAddress,
            amount,
            decimals,
            awaitConfirm = false, // confirm tx amount before return
        }: SetTokenAllowanceProps) => {
            const existingAllowance = await getTokenAllowance({
                tokenAddress,
                contractAddress,
            });

            const allowanceAmount = BigNumber.isBigNumber(amount)
                ? amount
                : ethers.utils.parseUnits(
                      strNumToDecimalPrecision(String(amount), decimals),
                      decimals
                  );

            const allowance = await setTokenAllowance({
                contractAddress,
                tokenAddress,
                amount: existingAllowance.add(allowanceAmount),
                decimals,
                awaitConfirm,
            });

            return allowance;
        },
        [getTokenAllowance, setTokenAllowance]
    );

    const setTokenAllowanceIfInsufficient = useCallback(
        async ({
            contractAddress,
            tokenAddress,
            amount,
            decimals,
            awaitConfirm = false, // confirm tx amount before return
        }: SetTokenAllowanceProps) => {
            const allowanceAmount = BigNumber.isBigNumber(amount)
                ? amount
                : ethers.utils.parseUnits(
                      strNumToDecimalPrecision(String(amount), decimals),
                      decimals
                  );

            const existingAllowance = BigNumber.from(
                await getTokenAllowance({
                    tokenAddress,
                    contractAddress,
                })
            );

            if (allowanceAmount.lte(existingAllowance)) return false;

            const allowance = await setTokenAllowance({
                contractAddress,
                tokenAddress,
                amount,
                decimals,
                awaitConfirm,
            });

            return allowance;
        },
        [getTokenAllowance, setTokenAllowance]
    );

    return {
        getTokenAllowance,
        setTokenAllowance,
        addToTokenAllowance,
        setTokenAllowanceIfInsufficient,
    };
};

export default useWalletAllowance;
