import { useEffect, useState } from "react";
import useVariableRatesContract from "admin/hooks/useVariableRatesContract";
import { useNotificationQueue } from "context/NotificationQueue";
import { NotificationType } from "components/Notification";
import Select from "components/Select";
import Field from "components/Field";
import Label from "components/Label";
import Form from "components/Form";
import Button from "components/Button";
import Input from "components/Input";
import Title from "components/Title";
import { Spacing } from "theme/spacing";
import SelectMultiple, {
    SelectMultipleOptionProps,
} from "components/SelectMultiple";
import { useWalletContext } from "context/WalletContext";

interface NewVariableRatesContractFormProps {
    availableTokens: any;
    factoryAddr: string; // Address
    onSubmit: any;
    closePopover: any;
}

const NewVariableRatesContractForm = ({
    availableTokens,
    factoryAddr,
    onSubmit,
    closePopover,
}: NewVariableRatesContractFormProps) => {
    const { createContract } = useVariableRatesContract();
    const { addNotification } = useNotificationQueue();
    const { walletConnected } = useWalletContext();
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    // TODO type state
    const [formState, setFormState] = useState<any>({
        companyName: ``,
        inboundTreasury: ``,
        outboundTreasury: ``,
        signer: ``,
        securityLevel: 0,
        baseFee: 0,
        variableFee: 0,
        selectedTokens: [],
        governor: ``,
    });

    const tokensOptions: SelectMultipleOptionProps[] = availableTokens.map(
        (token: any) => {
            return {
                label: token.symbol,
                value: token.symbol,
            };
        }
    );

    useEffect(() => {
        if (submitted) {
            closePopover();
        }
    }, [submitted]);

    const handleSubmit = async (evt: any) => {
        evt.preventDefault();
        setSubmitting(true);
        const {
            inboundTreasury,
            outboundTreasury,
            signer,
            securityLevel,
            baseFee,
            variableFee,
            selectedTokens,
            governor,
        } = formState;

        const tokens = selectedTokens.map((tokenSymbol: string) =>
            availableTokens.find((token: any) => tokenSymbol === token.symbol)
        );

        try {
            const [receipt, newContractAddr] = await createContract(
                baseFee,
                variableFee,
                tokens,
                inboundTreasury,
                outboundTreasury,
                signer,
                governor,
                securityLevel,
                factoryAddr
            );
            onSubmit({
                receipt,
                newContractAddr,
                templateType: 2,
                form: formState,
            });
            setSubmitted(true);
        } catch (err) {
            console.error(err);
            addNotification({
                msg: "Something went wrong",
                type: NotificationType.ERROR,
            });
            setSubmitting(false);
        }
    };

    const handleChangeFactory = (field: string) => {
        if (field === `selectedTokens`) {
            return (values: any) => {
                // On autofill `value` will be a stringified value.
                setFormState({
                    ...formState,
                    selectedTokens: values,
                });
            };
        }
        if (field === `securityLevel`) {
            return (evt: any) => {
                if (evt.target.value === 1) {
                    setFormState({
                        ...formState,
                        securityLevel: evt.target.value,
                        inboundTreasury:
                            formState.inboundTreasury === ""
                                ? "0x0000000000000000000000000000000000000000"
                                : formState.inboundTreasury,
                        outboundTreasury:
                            formState.outboundTreasury === ""
                                ? "0x0000000000000000000000000000000000000000"
                                : formState.outboundTreasury,
                    });
                } else {
                    setFormState({ ...formState, [field]: evt.target.value });
                }
            };
        }

        return (evt: any) => {
            // validate
            setFormState({ ...formState, [field]: evt.target.value });
        };
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Title level="h2" spacing={Spacing.sm}>
                New Variable Rate Contract
            </Title>
            <Field>
                <Label htmlFor="inboundTreasuryAddress">
                    Inbound Treasury Address
                </Label>
                <Input
                    name="inboundTreasuryAddress"
                    value={formState.inboundTreasury}
                    onChange={handleChangeFactory(`inboundTreasury`)}
                />
            </Field>
            <Field>
                <Label htmlFor="outboundTreasuryAddress">
                    Outbound Treasury Address
                </Label>
                <Input
                    name="outboundTreasuryAddress"
                    value={formState.outboundTreasury}
                    onChange={handleChangeFactory(`outboundTreasury`)}
                />
            </Field>
            <Field>
                <Label htmlFor="signerAddress">Signer Address</Label>
                <Input
                    name="signerAddress"
                    value={formState.signer}
                    onChange={handleChangeFactory(`signer`)}
                />
            </Field>
            <Field>
                <Label htmlFor="governorAddress">Governor Address</Label>
                <Input
                    name="governorAddress"
                    value={formState.governor}
                    onChange={handleChangeFactory(`governor`)}
                />
            </Field>
            <Field>
                <Label htmlFor="acceptedTokens">Accepted Tokens</Label>
                <SelectMultiple
                    name="acceptedTokens"
                    options={tokensOptions}
                    values={formState.selectedTokens}
                    onChange={handleChangeFactory(`selectedTokens`)}
                />
            </Field>
            <Field>
                <Label htmlFor="securityLevel">Security Level</Label>
                <Select
                    value={formState.securityLevel}
                    name="securityLevel"
                    onChange={handleChangeFactory(`securityLevel`)}
                    options={[
                        { value: 0, label: `Direct` },
                        { value: 1, label: `Platform` },
                    ]}
                />
            </Field>
            <Field>
                <Label htmlFor="baseFee">Base Fee ($)</Label>
                <Input
                    name="baseFee"
                    type="number"
                    value={formState.baseFee}
                    onChange={handleChangeFactory(`baseFee`)}
                    placeholder="0.15"
                    step="0.01"
                />
            </Field>
            <Field>
                <Label htmlFor="variableFee">Variable Fee (%)</Label>
                <Input
                    name="variableFee"
                    type="number"
                    value={formState.variableFee}
                    placeholder="0.1"
                    step="0.01"
                    onChange={handleChangeFactory(`variableFee`)}
                />
            </Field>
            <Button
                type="submit"
                loading={submitting}
                disabled={!walletConnected}
                full
            >
                {submitting ? "Deploying..." : "Deploy New Contract"}
            </Button>
        </Form>
    );
};

export default NewVariableRatesContractForm;
