import {
    ReactNode,
    createContext,
    useContext,
    useState,
    useEffect,
    useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addDays } from "date-fns";
import { getCookie, setCookie } from "utils/storage";
import { booleanFromString } from "utils/booleans";
import { UserRole } from "context/User";
import { useWalletContext } from "context/WalletContext";

export enum LoginType {
    WEB2 = "Web2",
    WEB3 = "Web3",
}

interface SessionProviderProps {
    children: ReactNode;
    loginType: LoginType;
}

interface SetWeb2Session {
    (
        token: string,
        entityId: string,
        companyName: string,
        roles: string[],
        delegatedSigning?: boolean,
        expiration?: Date,
        email?: string
    ): void;
}

interface SetWeb3Session {
    (
        token: string,
        walletAddress: string,
        roles: string[],
        expiration?: Date
    ): void;
}

export interface SessionProviderReturn {
    entityId: string;
    hasRole: (role: UserRole) => boolean;
    hasRoles: (matchAll: UserRole[]) => boolean;
    roles: UserRole[];
    getSessionToken: () => string;
    getCompanyName: () => string;
    getDelegatedSigning: () => boolean;
    setWeb2Session: SetWeb2Session;
    setWeb3Session: SetWeb3Session;
    killSession: () => void;
    sessionEmail: string;
}

const SessionContext = createContext<SessionProviderReturn>(
    {} as SessionProviderReturn
);

const SessionProvider = ({
    children,
    loginType,
    ...props
}: SessionProviderProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { walletConnected } = useWalletContext();
    const [sessionId, setSessionId] = useState<string>(``);
    const [sessionEmail, setSessionEmail] = useState<string>(``);
    const [isSessionReady, setIsSessionReady] = useState<boolean>(false);
    const redirectPath = useRef(`/`);

    const getSessionToken = () => {
        let tokenValue = getCookie(`token`) || ``;
        if (tokenValue && tokenValue !== `` && loginType === LoginType.WEB2)
            tokenValue = `Bearer ${tokenValue}`;

        return tokenValue;
    };

    const getCompanyName = () => {
        return getCookie(`companyName`) || ``;
    };

    const getDelegatedSigning = () => {
        const delegatedSigningString = getCookie(`delegatedSigning`);
        if (delegatedSigningString) {
            return booleanFromString(getCookie(`delegatedSigning`));
        } else {
            return false;
        }
    };

    const roles = (getCookie("roles")?.split(",") as UserRole[]) || [];

    const hasRole = (role: UserRole): boolean => {
        return roles?.includes(role);
    };

    const hasRoles = (matchAll: UserRole[]): boolean => {
        return matchAll.every((role) => roles?.includes(role));
    };

    const setWeb2Session: SetWeb2Session = (
        token,
        entityId,
        companyName,
        roles,
        delegatedSigning = false,
        expiration = addDays(new Date(), 1),
        email
    ) => {
        setCookie([
            { key: `token`, value: token, expiration },
            { key: `entityId`, value: entityId, expiration },
            { key: `companyName`, value: companyName, expiration },
            { key: `roles`, value: roles.join(`,`), expiration },
            {
                key: `delegatedSigning`,
                value: delegatedSigning.toString(),
                expiration,
            },
        ]);
        setSessionId(entityId || ``);
        if (entityId) {
            navigate(redirectPath.current);
            redirectPath.current = `/`;
        }

        if (email) {
            setCookie([{ key: `email`, value: email, expiration }]);
            setSessionEmail(email);
        }
    };

    const setWeb3Session: SetWeb3Session = (
        token,
        address,
        roles,
        expiration = addDays(new Date(), 1)
    ) => {
        setCookie([
            { key: `token`, value: token, expiration },
            { key: `walletAddress`, value: address, expiration },
            { key: `roles`, value: roles.join(","), expiration },
        ]);
        setSessionId(address || ``);
        if (address && token) {
            navigate(redirectPath.current);
            redirectPath.current = `/`;
        }
    };

    const killSession = () => {
        if (loginType === LoginType.WEB2) {
            setWeb2Session(``, ``, ``, [], false, new Date());
        } else if (loginType === LoginType.WEB3) {
            setWeb3Session(``, ``, [], new Date());
        }
    };

    useEffect(() => {
        // Ensures session state is stored before children begin rendering
        setIsSessionReady(true);
    }, []);

    // Handle Web2 authentication
    useEffect(() => {
        if (loginType !== LoginType.WEB2) return;

        const sessionToken = getSessionToken();
        const sessionEntityId = getCookie(`entityId`);
        const sessionEmail = getCookie(`email`);

        // If session data isn't set (expired or cleared)
        if (!sessionToken || !sessionEntityId) {
            if (
                !location.pathname.startsWith(`/login/`) &&
                location.pathname !== `/login`
            ) {
                redirectPath.current = location.pathname;
                navigate(`/login`);
            }
            return;
        }

        // Ensure that if sessionId is `` or out of sync with the cookies, we fix that
        if (sessionEntityId && (!sessionId || sessionId !== sessionEntityId)) {
            setSessionId(sessionEntityId);
        }

        if (sessionEmail) {
            setSessionEmail(sessionEmail);
        }
    }, [location.pathname, navigate, sessionId]);

    // Handle Web3 authentication
    useEffect(() => {
        if (loginType !== LoginType.WEB3) return;

        const sessionToken = getSessionToken();
        const sessionWalletAddress = getCookie(`walletAddress`);

        // If wallet isn't connected, or session data isn't set (expired or cleared), or doesn't match
        if (
            !sessionToken ||
            !walletConnected ||
            !sessionWalletAddress ||
            (walletConnected &&
                walletConnected.address !== sessionWalletAddress)
        ) {
            if (
                !location.pathname.startsWith(`/login/`) &&
                location.pathname !== `/login`
            ) {
                redirectPath.current = location.pathname;
                navigate(`/login`);
            }
            return;
        }

        // Ensure that if sessionId is `` or out of sync with the wallet, we fix that
        if (
            walletConnected &&
            (!sessionId || sessionId !== walletConnected.address)
        ) {
            setSessionId(walletConnected.address);
        }
    }, [location.pathname, navigate, walletConnected]);

    return (
        <SessionContext.Provider
            value={{
                entityId: loginType === LoginType.WEB2 ? sessionId : ``,
                hasRole,
                hasRoles,
                roles,
                getSessionToken,
                getCompanyName,
                getDelegatedSigning,
                setWeb2Session,
                setWeb3Session,
                killSession,
                sessionEmail,
            }}
            {...props}
        >
            {isSessionReady && children}
        </SessionContext.Provider>
    );
};

const useSession = (): SessionProviderReturn => {
    const context = useContext(SessionContext);
    if (context === undefined) {
        throw new Error(`useSession() must be used within a SessionProvider`);
    }
    return context;
};

export { SessionProvider, useSession };
