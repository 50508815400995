import * as S from "./styles";
import { FunctionComponent } from "react";
import Dropdown from "components/Dropdown";
import DropdownItem from "components/DropdownItem";
import VerticalDots from "components/icons/VerticalDots";
import CancelAccountForm from "company/components/CancelAccountForm";
import { useNavigate } from "react-router-dom";
import { CompanyAgreementResponse } from "company/types";
import { AgreementType } from "types/common-enums";
import { formatDateTimeFromSeconds } from "utils/datetime";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import { useModal } from "context/ModalProvider";

interface AuthorizedWalletManageProps {
    agreement: CompanyAgreementResponse;
}

const AuthorizedWalletManage: FunctionComponent<AuthorizedWalletManageProps> =
    ({ agreement }) => {
        const navigate = useNavigate();
        const { tokens } = useGetTokensMetadata();
        const { networks } = useGetNetworks();
        const { items } = useGetCompanyItems();
        const { openModal, closeModal } = useModal();

        const network = networks.find(
            (network) => network.id === agreement.networkId
        );
        const token = tokens.find(
            (token) =>
                token.address === agreement.token &&
                token.networkId === agreement.networkId
        );

        const agreementItems = items.filter((item) => {
            return agreement.items.includes(item.id);
        });

        const agreementCanceled =
            agreement.status === AgreementType[AgreementType.Canceled];

        const handleCancelAccountFormModal = () => {
            if (!token) return;
            openModal(
                <CancelAccountForm
                    onClose={closeModal}
                    onComplete={closeModal}
                    agreementId={agreement.id}
                    createdAt={agreement.createdAt}
                    senderWallet={agreement.sender.wallet}
                    network={network}
                    senderEmail={agreement.sender.email}
                    items={agreementItems}
                    token={token}
                />,
                `Cancel Agreement`
            );
        };

        if (agreementCanceled) {
            return (
                <S.CanceledDate>
                    Canceled:
                    <br />
                    {formatDateTimeFromSeconds(agreement.endDate)}
                </S.CanceledDate>
            );
        }

        if (!token || !agreementItems) return <></>;

        return (
            <Dropdown
                anchorEl={
                    <a href="#menu" onClick={(event) => event.preventDefault()}>
                        <VerticalDots width="1rem" height="1rem" fill="#888" />
                    </a>
                }
            >
                <DropdownItem
                    onClick={() =>
                        navigate(
                            `/pay?itemId=${agreement.items[0]}&fromAddress=${agreement.sender.wallet}&tokenAddress=${agreement.token}`
                        )
                    }
                >
                    Pay
                </DropdownItem>

                {!agreementCanceled && (
                    <DropdownItem onClick={handleCancelAccountFormModal}>
                        Cancel
                    </DropdownItem>
                )}
            </Dropdown>
        );
    };

export default AuthorizedWalletManage;
