import { useCallback } from "react";
import { useNotificationQueue } from "context/NotificationQueue";
import { useCheckoutForm } from "checkout/context/CheckoutForm";
import { CheckoutSteps } from "checkout/hooks/useCheckoutStateMachine";
import Button from "components/Button";
import { NotificationType } from "components/Notification";
import { useWalletContext } from "context/WalletContext";

type ButtonConfirmPaymentProps = {
    showWrapMoreForm: boolean;
};
const ButtonConfirmPayment = ({
    showWrapMoreForm,
}: ButtonConfirmPaymentProps) => {
    const { addNotification, removeNotification } = useNotificationQueue();
    const { walletConnected, isNetworkSetting } = useWalletContext();
    const {
        checkoutFormStep,
        network,
        token,
        authToken,
        createNewAuthToken,
        confirmPayment,
        confirmContact,
        hasSufficientBalance,
        email,
    } = useCheckoutForm();

    const createAuthToken = useCallback(async () => {
        if (!authToken) {
            const signingNotificationId = addNotification({
                msg: `Signing message with your wallet...`,
                type: NotificationType.WORKING,
            });
            createNewAuthToken()
                .then(() => {
                    addNotification({
                        msg: `Message signed successfully`,
                        type: NotificationType.SUCCESS,
                    });
                    confirmPayment();
                })
                .catch(() => {
                    addNotification({
                        msg: `A signature was not obtained from your connected wallet`,
                        type: NotificationType.ERROR,
                    });
                })
                .finally(() => {
                    removeNotification(signingNotificationId);
                });
            return;
        }
    }, [
        authToken,
        createNewAuthToken,
        addNotification,
        removeNotification,
        confirmPayment,
    ]);

    const renderButton = (
        text: string,
        disabled?: boolean,
        onClick?: () => void
    ) => {
        return (
            <Button full disabled={disabled} onClick={onClick}>
                {text}
            </Button>
        );
    };

    // No wallet, no render
    if (!walletConnected) return <></>;

    // User needs to select network & token
    if (!network || !token)
        return renderButton("Select network and token", true);

    // Chain is being swapped in the wallet
    if (isNetworkSetting) return renderButton("Switching networks...", true);

    // Don't go no money
    if (!hasSufficientBalance)
        return renderButton(`Insufficient ${token?.symbol} balance`, true);

    // User needs to login
    if (!authToken)
        return renderButton(`Sign to continue`, false, createAuthToken);

    // User is trying to wrap more before next step
    if (showWrapMoreForm)
        return renderButton(
            `Continue without wrapping`,
            false,
            email ? confirmContact : confirmPayment
        );

    // Only show "Continue" if on Step 1
    if (checkoutFormStep === CheckoutSteps.PAYMENT)
        return renderButton(
            `Continue`,
            false,
            email ? confirmContact : confirmPayment
        );

    return <></>;
};

export default ButtonConfirmPayment;
