import {
    forwardRef,
    useImperativeHandle,
    Ref,
    useState,
    useRef,
    useCallback,
    useEffect,
    useMemo,
} from "react";
import { Company } from "company/types";
import WalletConnectPanel from "components/WalletConnectPanel";
import TokenAllowanceTable, {
    TokenAllowanceTableRef,
} from "company/components/TokenAllowanceTable";
import { GeneralTokenDetailsResponse, postAgreeToItems } from "api";
import { useAuthorizationToken } from "checkout/hooks/useAuthorizationToken";
import { useNotificationQueue } from "context/NotificationQueue";
import { ethers } from "ethers";
import Button from "components/Button";
import { toDollar } from "utils/financial";
import Check from "components/icons/Check";
import Close from "components/icons/Close";
import * as S from "./styles";
import Label from "components/Label";
import WalletNetworkSelect from "company/components/WalletNetworkSelect";
import { firstToUpper } from "utils/strings";
import { useSafeApi } from "hooks/useSafeApi";
import ThreeDotsPending from "components/icons/ThreeDotsPending";
import WalletConnectMultiSig from "components/WalletConnectMultiSig";
import { NotificationType } from "components/Notification";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import { useGetCompanyAgreements } from "company/hooks/useGetCompanyAgreements";
import { useUser } from "context/User";
import colors from "theme/colors";
import Section from "components/Section";
import SubSection from "components/SubSection";
import Title from "components/Title";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import { useWalletContext } from "context/WalletContext";
import { AvailableNetwork } from "default-variables";

export enum TokenRowState {
    Initial = "Initial",
    Updating = "Updating",
    Success = "Updated",
    Error = "Error",
}

export type TokenRow = {
    token: GeneralTokenDetailsResponse;
    currentAllowance: number;
    allowance: number;
    usdValue?: number;
    selected: boolean;
    state: TokenRowState;
};

export type UpdateTokenRowProps = {
    selected?: boolean;
    allowance?: number;
    currentAllowance?: number;
    state?: TokenRowState;
};

export interface AllowanceNewWalletProps {
    entity: Company.Entity;
    item: Company.Item;
    setCanCloseModal: React.Dispatch<React.SetStateAction<boolean>>;
    onSuccess?: () => void;
}

export type AllowanceNewWalletRef = {};

function AllowanceNewWallet(
    { entity, item, setCanCloseModal, onSuccess }: AllowanceNewWalletProps,
    ref: Ref<AllowanceNewWalletRef>
) {
    useImperativeHandle(ref, () => ({}));
    const { getAvailableNetworks } = useAvailableNetworks();
    const {
        walletConnected,
        networkConnected,
        getTokenAllowance,
        setTokenAllowance,
    } = useWalletContext();

    const tokenAllowanceTableRef = useRef<TokenAllowanceTableRef>(null);
    const { getEmail } = useUser();

    const { authToken, createNewAuthToken } = useAuthorizationToken();

    const { networks } = useGetNetworks();

    const { tokens, getTokenRate } = useGetTokensMetadata();
    const { agreements, getCompanyAgreementsRefetch } =
        useGetCompanyAgreements();
    const {
        config: { contracts },
    } = useGetCompanyConfig();

    const { sendApproveAllownaceForSafe } = useSafeApi();
    const [loadingTokenRows, setLoadingTokensRows] = useState<boolean>(false);
    const [updatingAllowances, setUpdatingAllowance] = useState<boolean>(false);
    const tokenRowsRef = useRef<TokenRow[]>([]);
    const { addNotification, removeNotification } = useNotificationQueue();
    const [, forceUpdate] = useState({});

    const connectedToAvailableChain = useMemo(() => {
        return getAvailableNetworks().find(
            (chain: AvailableNetwork) =>
                networkConnected?.networkId === chain.networkId
        );
    }, [networkConnected?.networkId, getAvailableNetworks]);

    // Get network based on current chain connected
    const network = useMemo(() => {
        return networks.find(
            (network) => network.hexId === networkConnected?.networkId
        );
    }, [networkConnected?.networkId, networks]);

    // Get contract based on network
    const contract = useMemo(() => {
        return contracts.find((contract) => contract.networkId === network?.id);
    }, [contracts, network?.id]);

    // Get the tokens related to
    // - the item (acceptedToken)
    // - the network
    const itemTokens = useMemo(() => {
        if (!network) return [];
        return tokens.filter(
            (token) =>
                item.acceptedTokens.includes(
                    token.address || token.address.toLocaleLowerCase()
                ) && token.networkId === network.id
        );
    }, [item.acceptedTokens, network, tokens]);

    // Get the existing agreements related to
    // - the item (includes)
    // - the connected wallet (proxyFor / address)
    // - the agreement status (not cancelled)
    // - the entity

    const itemAgreements = useMemo(() => {
        if (!network) return [];
        if (!walletConnected?.address) return [];

        return agreements.filter((agreement) => {
            return (
                agreement.networkId === network.id &&
                agreement.sender.wallet ===
                    (walletConnected?.proxyFor || walletConnected.address) &&
                agreement.items.includes(item.id) &&
                agreement.status !== "Canceled" &&
                agreement.entity === entity.entityId
            );
        });
    }, [
        agreements,
        entity.entityId,
        item.id,
        walletConnected?.address,
        walletConnected?.proxyFor,
        network,
    ]);

    const updateTokenRow = useCallback(
        (tokenRowToUpdate: TokenRow, updates: UpdateTokenRowProps) => {
            // Find the index of the row that needs to be updated
            const rowIndex = tokenRowsRef.current.findIndex(
                (tokenRow) =>
                    tokenRow.token.symbol === tokenRowToUpdate.token.symbol
            );
            if (rowIndex > -1) {
                const updatedTokenRows = [...tokenRowsRef.current];
                updatedTokenRows[rowIndex] = {
                    ...tokenRowsRef.current[rowIndex],
                    ...updates,
                };
                tokenRowsRef.current = updatedTokenRows;

                forceUpdate({});
            }
        },
        [tokenRowsRef]
    );

    // Only enable button if user has changed allowance
    const tokenRowsAllowanceUpdated = tokenRowsRef.current.some(
        (tokenRow) => tokenRow.allowance !== tokenRow.currentAllowance
    );

    const tokenRowsSelected = tokenRowsRef.current.filter(
        (tokenRow) => tokenRow.selected
    );

    // Note: If we re-use this somewhere else, we should move to a hook or provider
    const fetchBalancesAndAllowances = useCallback(async () => {
        if (!contract || !walletConnected?.address || itemTokens.length === 0)
            return;

        const updatedTokenRows: TokenRow[] = await Promise.all(
            itemTokens.map(async (token) => {
                const currentAllowance = await getTokenAllowance({
                    contractAddress: contract.address,
                    tokenAddress: token.address,
                });

                const allowance = ethers.utils.formatUnits(
                    currentAllowance,
                    token.decimals
                );
                const rate = getTokenRate(token)?.rate;

                const tokenRow: TokenRow = {
                    token: token,
                    usdValue: rate,
                    currentAllowance: Number(allowance),
                    allowance: Number(allowance),
                    selected: Number(allowance) > 0,
                    state: TokenRowState.Initial,
                };
                return tokenRow;
            })
        );
        tokenRowsRef.current = updatedTokenRows;
    }, [
        contract,
        getTokenRate,
        getTokenAllowance,
        itemTokens,
        walletConnected?.address,
    ]);

    // Need to refetch on address change
    useEffect(() => {
        if (
            !contract ||
            !walletConnected?.address ||
            itemTokens.length === 0 ||
            !connectedToAvailableChain
        ) {
            tokenRowsRef.current = [];
            setLoadingTokensRows(false);
            return;
        }

        if (walletConnected?.address) {
            setLoadingTokensRows(true);
            fetchBalancesAndAllowances().then(() => {
                setLoadingTokensRows(false);
            });
        }
    }, [
        walletConnected?.proxyFor,
        walletConnected?.address,
        networkConnected,
        networkConnected?.networkId,
        itemTokens,
        contract,
        connectedToAvailableChain,
        fetchBalancesAndAllowances,
    ]);

    const createAgreement = useCallback(
        async (authToken: string, tokenAddress: string) => {
            if (!walletConnected?.address || !networkConnected?.networkId)
                return;

            const { response, error } = await postAgreeToItems(
                walletConnected?.proxyFor || walletConnected.address,
                {
                    network: parseInt(networkConnected.networkId, 16),
                    email: getEmail(),
                    entityId: entity.entityId,
                    token: tokenAddress,
                    itemIds: [item.id],
                    pendingMessageHash: ``,
                },
                {
                    Authorization: authToken,
                    address: walletConnected.address,
                }
            );

            if (!response.ok) {
                let errorForDisplay = "Failed to create the agreement";

                if (error && error.code === 400 && error.message) {
                    errorForDisplay = error.message;
                }

                addNotification({
                    msg: errorForDisplay,
                    type: NotificationType.ERROR,
                });
            } else {
                await getCompanyAgreementsRefetch();
            }
        },
        [
            walletConnected?.address,
            walletConnected?.proxyFor,
            networkConnected?.networkId,
            entity.entityId,
            item.id,
            getCompanyAgreementsRefetch,
            getEmail,
            addNotification,
        ]
    );

    const setAllowanceForTokenRow = useCallback(
        async (tokenRow: TokenRow, authToken: string) => {
            let success = false;
            if (!contract) return false;

            try {
                updateTokenRow(tokenRow, {
                    state: TokenRowState.Updating,
                });

                if (walletConnected?.proxyFor) {
                    await sendApproveAllownaceForSafe({
                        token: tokenRow.token,
                        contract: contract.address,
                        amount: String(tokenRow.allowance),
                    });
                } else {
                    await setTokenAllowance({
                        contractAddress: contract.address,
                        tokenAddress: tokenRow.token.address,
                        amount: String(tokenRow.allowance),
                        decimals: tokenRow.token.decimals,
                    });
                }

                const existingAgreement = itemAgreements.find(
                    (agreement) =>
                        agreement.token === tokenRow.token.address &&
                        agreement.networkId === tokenRow.token.networkId
                );

                if (!existingAgreement) {
                    await createAgreement(authToken, tokenRow.token.address);
                }

                updateTokenRow(tokenRow, {
                    currentAllowance: tokenRow.allowance,
                    state: TokenRowState.Success,
                });
            } catch (error) {
                addNotification({
                    msg: `Failed to updated allowance for ${tokenRow.token.symbol}`,
                    type: NotificationType.ERROR,
                });
                updateTokenRow(tokenRow, {
                    state: TokenRowState.Error,
                });
            }

            return success;
        },
        [
            contract,
            updateTokenRow,
            walletConnected?.proxyFor,
            itemAgreements,
            sendApproveAllownaceForSafe,
            setTokenAllowance,
            createAgreement,
            addNotification,
        ]
    );

    const setAllowanceForAllTokens = useCallback(async () => {
        setUpdatingAllowance(true);
        setCanCloseModal(false);
        let authTokenForRequest = authToken;

        if (!authTokenForRequest) {
            try {
                authTokenForRequest = await createNewAuthToken();
            } catch (error) {
                addNotification({
                    msg: "Failed to get authorization",
                    type: NotificationType.ERROR,
                });
            }
        }
        const notificationLoadingId = addNotification({
            msg: `Updating allowances`,
            type: NotificationType.WORKING,
        });

        await Promise.all(
            tokenRowsSelected.map(async (tokenRow) => {
                return await setAllowanceForTokenRow(
                    tokenRow,
                    authTokenForRequest
                );
            })
        );

        removeNotification(notificationLoadingId);
        setUpdatingAllowance(false);
        setCanCloseModal(true);
    }, [
        addNotification,
        authToken,
        createNewAuthToken,
        removeNotification,
        setAllowanceForTokenRow,
        tokenRowsSelected,
        setCanCloseModal,
    ]);

    const success =
        tokenRowsRef.current.some(
            (tokenRow) => tokenRow.state === TokenRowState.Success
        ) && !updatingAllowances;
    if (success) {
        if (onSuccess) onSuccess();
        return (
            <>
                <Title level="h3">Details</Title>
                {walletConnected?.proxyFor && (
                    <>
                        <S.MultiSigPendingDetailsSection>
                            <div>
                                <ThreeDotsPending
                                    fill={colors.warning80}
                                    width="3rem"
                                    height="3rem"
                                />
                            </div>

                            <div>
                                <Label>
                                    Gather remaining signatures in Safe App
                                </Label>
                                <p>
                                    Once all the requisite signers have
                                    completed their part, you will be able to
                                    schedule payments using this wallet.
                                </p>
                            </div>
                        </S.MultiSigPendingDetailsSection>
                    </>
                )}
                {network && (
                    <SubSection title="network">
                        {firstToUpper(network.name)}
                    </SubSection>
                )}

                <SubSection title="Wallet">
                    {walletConnected?.proxyFor ||
                        walletConnected?.address ||
                        ``}
                </SubSection>
                <SubSection
                    title={
                        tokenRowsSelected.length === 1 ? "Amount" : "Amounts"
                    }
                >
                    <S.AuthorizationsList>
                        {tokenRowsSelected.map((tokenRow) => (
                            <S.AuthorizationItem key={tokenRow.token.address}>
                                {tokenRow.currentAllowance}{" "}
                                {tokenRow.token.symbol} (
                                {tokenRow.usdValue &&
                                    toDollar(
                                        tokenRow.allowance *
                                            100 *
                                            (tokenRow.usdValue / 10000)
                                    )}
                                )
                                {walletConnected?.proxyFor &&
                                    tokenRow.state ===
                                        TokenRowState.Success && (
                                        <S.AuthorizationPending>
                                            Pending signatures
                                        </S.AuthorizationPending>
                                    )}
                                {!walletConnected?.proxyFor &&
                                    tokenRow.state ===
                                        TokenRowState.Success && (
                                        <Check fill="#29C287" width="0.8rem" />
                                    )}
                                {tokenRow.state === TokenRowState.Error && (
                                    <S.AuthorizationFailed>
                                        <Close width="0.8rem" />
                                        Transaction canceled by user
                                    </S.AuthorizationFailed>
                                )}
                            </S.AuthorizationItem>
                        ))}
                    </S.AuthorizationsList>
                </SubSection>
            </>
        );
    }

    const buttonEnabled =
        tokenRowsAllowanceUpdated && tokenRowsSelected.length > 0;

    let buttonText = "Authorize";
    if (loadingTokenRows) buttonText = "Loading allowance...";
    if (updatingAllowances) buttonText = "Updating allowances...";

    return (
        <Section>
            <SubSection title="Wallet">
                <WalletConnectPanel full>Connect wallet</WalletConnectPanel>
                <S.MultiSigWrapper>
                    <WalletConnectMultiSig />
                </S.MultiSigWrapper>
            </SubSection>

            {walletConnected && (
                <>
                    <SubSection title="Network">
                        <WalletNetworkSelect
                            disabled={updatingAllowances}
                            withContractOnly
                        />
                    </SubSection>
                    {connectedToAvailableChain && network && (
                        <SubSection title="Authorized tokens">
                            <TokenAllowanceTable
                                tokenRows={tokenRowsRef.current}
                                updateTokenRow={updateTokenRow}
                                ref={tokenAllowanceTableRef}
                                disabled={updatingAllowances}
                                network={network}
                            />
                            <Button
                                full
                                disabled={!buttonEnabled}
                                loading={loadingTokenRows || updatingAllowances}
                                onClick={() => setAllowanceForAllTokens()}
                            >
                                {buttonText}
                            </Button>
                        </SubSection>
                    )}
                </>
            )}
        </Section>
    );
}
export default forwardRef(AllowanceNewWallet);
