import {
    createApproveCheckedInstruction,
    getAccount,
    getMint,
    getAssociatedTokenAddress,
} from "@solana/spl-token";
import {
    BlockheightBasedTransactionConfirmationStrategy,
    ComputeBudgetProgram,
    PublicKey,
    RpcResponseAndContext,
    SignatureResult,
    Transaction,
    TransactionSignature,
} from "@solana/web3.js";
import { tokenABI } from "default-variables";
import { BigNumber, ethers } from "ethers";
import {
    EvmProvider,
    EvmWalletSigner,
    SolProvider,
    SolWalletSigner,
} from "types/common";

export const getEvmTokenBalance = async ({
    provider,
    walletAddress,
    tokenAddress,
}: {
    provider: EvmProvider;
    walletAddress: string;
    tokenAddress?: string | null;
}): Promise<number> => {
    let decimals, balance;
    try {
        if (tokenAddress) {
            const erc20 = new ethers.Contract(tokenAddress, tokenABI, provider);

            decimals = await erc20.decimals();
            balance = await erc20.balanceOf(walletAddress);
        } else {
            // Native token
            balance = await provider.getBalance(walletAddress);
            decimals = 18;
        }
    } catch (error) {
        // [ ] This should probably throw, rather than log and leave the catch up to the caller
        console.error(`Unable to get EVM balance: ${error}`);
        return 0;
    }

    // [ ] Maybe we need to consider just returning a BigNumber here and adjusting that in the wallet
    return Number(ethers.utils.formatUnits(balance, decimals));
};

export const getSolTokenBalance = async ({
    provider,
    walletAddress,
    tokenAddress,
}: {
    provider: SolProvider;
    walletAddress: string;
    tokenAddress?: string | null; // mint
}): Promise<number> => {
    let decimals, balance;
    try {
        if (tokenAddress) {
            const tokenAccount = await getAssociatedTokenAddress(
                new PublicKey(tokenAddress),
                new PublicKey(walletAddress)
            );

            const tokenAccountBalance = await provider.getTokenAccountBalance(
                tokenAccount
            );

            balance = Number(tokenAccountBalance.value.amount);
            decimals = tokenAccountBalance.value.decimals;
        } else {
            // Native token
            balance = await provider.getBalance(new PublicKey(walletAddress));
            decimals = 9;
        }
    } catch (error) {
        // [ ] This should probably throw, rather than log and leave the catch up to the caller
        console.error(`Unable to get Solana balance: ${error}`);
        return 0;
    }

    // [ ] Maybe we need to consider just returning a BigNumber here and adjusting that in the wallet
    return Number(ethers.utils.formatUnits(balance, decimals));
};

export const getEvmTokenAllowance = async ({
    provider,
    walletAddress,
    contractAddress,
    tokenAddress,
}: {
    provider: EvmProvider;
    walletAddress: string;
    contractAddress: string;
    tokenAddress: string;
}): Promise<BigNumber> => {
    const erc20 = new ethers.Contract(tokenAddress, tokenABI, provider);
    return await erc20.allowance(walletAddress, contractAddress);
};

export const getSolTokenAllowance = async ({
    provider,
    walletAddress,
    contractAddress,
    tokenAddress,
}: {
    provider: SolProvider;
    walletAddress: string;
    contractAddress: string;
    tokenAddress: string; // mint
}): Promise<BigNumber> => {
    const tokenAccount = await getAssociatedTokenAddress(
        new PublicKey(tokenAddress),
        new PublicKey(walletAddress)
    );

    const { delegate, delegatedAmount } = await getAccount(
        provider,
        tokenAccount
    );

    if (delegate?.toString() !== contractAddress) {
        throw new Error("Delegate does not match contract address");
    }

    return BigNumber.from(delegatedAmount.toString());
};

type SetEvmTokenAllowanceParams<T extends boolean> = {
    signer: EvmWalletSigner;
    contractAddress: string;
    tokenAddress: string;
    amount: BigNumber;
    awaitConfirm?: T;
};

type SetSolTokenAllowanceParams<T extends boolean> = {
    provider: SolProvider;
    signer: SolWalletSigner;
    walletAddress: string;
    contractAddress: string;
    tokenAddress: string; // mint
    amount: BigNumber;
    decimals: number;
    awaitConfirm?: T;
};

type SetTokenAllowanceReturnType<T extends boolean> = T extends true
    ? BigNumber
    : BigNumber;

export const setEvmTokenAllowance = async <T extends boolean>({
    signer,
    contractAddress,
    tokenAddress,
    amount,
    awaitConfirm,
}: SetEvmTokenAllowanceParams<T>): Promise<SetTokenAllowanceReturnType<T>> => {
    const erc20 = new ethers.Contract(tokenAddress, tokenABI, signer);

    const allowanceTx = await erc20.approve(contractAddress, amount.toBigInt());

    if (awaitConfirm) {
        const receipt = await allowanceTx.wait();

        if (receipt.status === 0)
            return Promise.reject(
                `There was a problem registering your allowance increase`
            );
    }

    // [ ] Returning the receipt/signature may be useful

    // Note this simply returns the value passed in as the amount, but has not verified it through a requst
    return Promise.resolve(amount as SetTokenAllowanceReturnType<T>);
};

export const setSolTokenAllowance = async <T extends boolean>({
    provider,
    signer,
    walletAddress,
    contractAddress,
    tokenAddress,
    amount,
    decimals,
    awaitConfirm,
}: SetSolTokenAllowanceParams<T>): Promise<SetTokenAllowanceReturnType<T>> => {
    const tokenAccount = await getAssociatedTokenAddress(
        new PublicKey(tokenAddress),
        new PublicKey(walletAddress)
    );

    const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: 1,
    });

    const transaction = new Transaction().add(addPriorityFee).add(
        createApproveCheckedInstruction(
            tokenAccount, // token account
            new PublicKey(tokenAddress), // mint
            new PublicKey(contractAddress), // delegate
            new PublicKey(walletAddress), // owner of token account
            amount.toBigInt(),
            decimals
        )
    );

    const blockhash = await provider.getLatestBlockhash();
    transaction.recentBlockhash = blockhash.blockhash;
    transaction.feePayer = new PublicKey(walletAddress);

    const { signature }: { signature: TransactionSignature } =
        await signer.signAndSendTransaction(transaction);

    if (awaitConfirm) {
        const { value }: RpcResponseAndContext<SignatureResult> =
            await provider.confirmTransaction(
                {
                    signature,
                } as BlockheightBasedTransactionConfirmationStrategy,
                "confirmed"
            );

        if (value.err) {
            throw value.err;
        }
    }

    // [ ] Returning signature/receipt may be useful

    // Note this simply returns the value passed in as the amount, but has not verified it through a requst
    return Promise.resolve(amount as SetTokenAllowanceReturnType<T>);
};
