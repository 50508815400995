import styled from "styled-components";
import { colorsDeprecated as c } from "global-style";

export const Loading = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const DefinitionList = styled.dl`
    margin: 0;
    & > div {
        display: flex;
        align-items: center;
        gap: 0.333rem;
    }
    dt {
        display: inline-block;
        color: ${c.lighterOnSurface};
    }
    dd {
        display: inline-flex;
        gap: 0.333rem;
        margin: 0;
    }
`;
