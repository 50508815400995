import { useEffect, useState } from "react";
import { useTokens } from "hooks/useTokens";
import useSubscriptionContract from "admin/hooks/useSubscriptionContract";
import { frequencyToSeconds } from "utils/datetime";
import {
    View as NewSubscriptionPlanForm,
    defaultState as defaultStateNewPlanForm,
} from "admin/components/Forms/NewItemForm";
import { useNotificationQueue } from "context/NotificationQueue";
import { NotificationType } from "components/Notification";
import Title from "components/Title";
import { Spacing } from "theme/spacing";
import Button from "components/Button";
import Form from "components/Form";
import Field from "components/Field";
import Label from "components/Label";
import Input from "components/Input";
import SelectMultiple, {
    SelectMultipleOptionProps,
} from "components/SelectMultiple";
import { useWalletContext } from "context/WalletContext";

interface NewSubscriptionContractFormProps {
    availableTokens: any;
    onSubmit: any;
    factoryAddr: string; // Address
    closePopover: any;
}

interface ViewProps {
    state: any;
    onChangeFactory: any;
    availableTokens: any;
}

export const View = ({
    state,
    onChangeFactory,
    availableTokens,
}: ViewProps) => {
    const tokensOptions: SelectMultipleOptionProps[] = availableTokens.map(
        (token: any) => {
            return {
                label: token.symbol,
                value: token.symbol,
            };
        }
    );

    return (
        <>
            <Field>
                <Label htmlFor="companyName">Company Name</Label>
                <Input
                    name="companyName"
                    value={state.companyName}
                    onChange={onChangeFactory(`companyName`)}
                />
            </Field>

            <Field>
                <Label htmlFor="receveiableAddress">Receivables Address</Label>
                <Input
                    name="receveiableAddress"
                    value={state.receivableAddr}
                    onChange={onChangeFactory(`receivableAddr`)}
                />
            </Field>

            <Field>
                <Label htmlFor="acceptedTokens">Accepted Tokens</Label>
                <SelectMultiple
                    name="acceptedTokens"
                    values={state.selectedTokens}
                    onChange={onChangeFactory(`selectedTokens`)}
                    options={tokensOptions}
                />
            </Field>

            <NewSubscriptionPlanForm form={state} onChange={onChangeFactory} />
        </>
    );
};

const NewSubscriptionContractForm = ({
    availableTokens,
    onSubmit,
    closePopover,
    factoryAddr,
}: NewSubscriptionContractFormProps) => {
    const { getTokenOnNetwork }: any = useTokens();
    const { networkConnected } = useWalletContext();
    const { createContract } = useSubscriptionContract();
    const { addNotification } = useNotificationQueue();
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    // TODO type state
    const [formState, setFormState] = useState<any>({
        companyName: ``,
        receivableAddr: ``,
        selectedTokens: [],
        ...defaultStateNewPlanForm,
    });

    useEffect(() => {
        if (submitted) {
            closePopover();
        }
    }, [submitted]);

    const handleSubmit = async (evt: any) => {
        evt.preventDefault();
        setSubmitting(true);
        const {
            name,
            frequencyCount,
            frequencyType,
            price,
            deposit,
            fee,
            selectedTokens,
            receivableAddr,
        } = formState;
        const wETH = getTokenOnNetwork(networkConnected?.networkId, `WETH`);

        const tokens = selectedTokens.map((tokenSymbol: string) =>
            availableTokens.find((token: any) => tokenSymbol === token.symbol)
        );
        try {
            const [receipt, newContractAddr] = await createContract(
                {
                    name,
                    frequency: frequencyToSeconds(
                        frequencyCount,
                        frequencyType
                    ),
                    amount: price,
                    stakeAmount: deposit,
                    fee: fee / 100,
                },
                tokens,
                wETH.tokenAddress,
                receivableAddr,
                factoryAddr
            );

            onSubmit({
                receipt,
                newContractAddr,
                templateType: 1,
                form: formState,
            });
            setSubmitted(true);
        } catch (err) {
            console.error(err);
            addNotification({
                msg: "Something went wrong",
                type: NotificationType.ERROR,
            });
            setSubmitting(false);
        }
    };

    const handleChangeFactory = (field: string) => {
        if (field === `selectedTokens`) {
            return (values: any) => {
                // On autofill `value` will be a stringified value.
                setFormState({
                    ...formState,
                    selectedTokens: values,
                });
            };
        }

        return (evt: any) => {
            // validate
            setFormState({ ...formState, [field]: evt.target.value });
        };
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Title level="h2" spacing={Spacing.sm}>
                New Subscription Contract
            </Title>
            <View
                state={formState}
                onChangeFactory={handleChangeFactory}
                availableTokens={availableTokens}
            />
            <Button
                type="submit"
                full
                disabled={!networkConnected}
                loading={submitting}
            >
                {submitting ? "Deploying..." : "Deploy New Contract"}
            </Button>
        </Form>
    );
};

export default NewSubscriptionContractForm;
