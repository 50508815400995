import * as S from "./styles";
import { Ref, forwardRef, useEffect, useState } from "react";
import Anchor from "components/Anchor";
import Label from "components/Label";
import Input from "components/Input";
import Button from "components/Button";
import Hint from "components/Hint";
import Form from "components/Form";
import {
    postSelfServeEmailConfirmationCode,
    PostSelfServeCreateAccountRequest,
    postSelfServeCreateAccount,
    SelfServeNewUser,
    PostSelfServeCreateAccountResponse,
} from "api";
import useCustomMutation from "hooks/useCustomMutation";
import {
    CreateItemRequest,
    EntityInboundTreasuriesByNetworkId,
} from "company/types";
import { SelfServerEntityDetailsOnly } from "company/routes/SelfServe";
import SubSection from "components/SubSection";
import { Spacing } from "theme/spacing";
import { clarity } from "react-microsoft-clarity";

interface SelfServeSubmitFormProps {
    selfServeNewUser?: SelfServeNewUser;
    selfServeNewUserValid: boolean;
    selfServeNewEntityConfig?: SelfServerEntityDetailsOnly;
    selfServeNewEntityConfigValid: boolean;
    createItemRequests: CreateItemRequest[];
    selfServeInboundTreasuries: EntityInboundTreasuriesByNetworkId;
    onSuccess: (
        postSelfServeCreateAccountData: PostSelfServeCreateAccountResponse
    ) => void;
}

export type SelfServeSubmitFormRef = {};

function SelfServeSubmitForm(
    {
        selfServeNewUser,
        selfServeNewUserValid,
        selfServeNewEntityConfig,
        selfServeNewEntityConfigValid,
        createItemRequests,
        selfServeInboundTreasuries,
        onSuccess,
    }: SelfServeSubmitFormProps,
    ref: Ref<SelfServeSubmitFormRef>
) {
    const {
        isPending: postSelfServeEmailConfirmationCodeIsPending,
        isSuccess: postSelfServeEmailConfirmationCodeIsSuccess,
        isIdle: postSelfServeEmailConfirmationCodeIsIdle,
        mutate: postSelfServeEmailConfirmationCodeMutate,
        submittedAt: postSelfServeEmailConfirmationCodeSubmittedAt,
    } = useCustomMutation({
        mutationFn: postSelfServeEmailConfirmationCode,
        mutationKey: ["postSelfServeEmailConfirmationCode"],
    });

    const {
        data: postSelfServeCreateAccountData,
        isPending: postSelfServeCreateAccountIsPending,
        mutate: postSelfServeCreateAccountMutate,
    } = useCustomMutation({
        mutationFn: postSelfServeCreateAccount,
        mutationKey: ["postSelfServeCreateAccount"],
    });

    const [confirmationCode, setConfirmationCode] =
        useState<PostSelfServeCreateAccountRequest["code"]>();

    const sendConfirmationCode = async () => {
        if (!selfServeNewUser) return false;
        postSelfServeEmailConfirmationCodeMutate({
            email: selfServeNewUser.email,
        });
    };

    const onSubmit = async () => {
        if (!selfServeNewUser) return false;
        if (!selfServeNewEntityConfig) return false;
        if (createItemRequests.length !== 1) return false;

        if (postSelfServeEmailConfirmationCodeIsIdle) {
            sendConfirmationCode();
            if (clarity.hasStarted()) clarity.setTag("SS code sent", "true");
        } else if (
            postSelfServeEmailConfirmationCodeIsSuccess &&
            confirmationCode
        ) {
            const request: PostSelfServeCreateAccountRequest = {
                code: confirmationCode,
                items: createItemRequests,
                entity: {
                    ...selfServeNewEntityConfig,
                    inboundTreasuries: selfServeInboundTreasuries,
                },
                user: selfServeNewUser,
            };

            if (clarity.hasStarted())
                clarity.setTag("SS Complete", selfServeNewUser.email);

            postSelfServeCreateAccountMutate(request);
        }
    };

    const validItems = createItemRequests.length === 1;

    const validConfirmationCode =
        !postSelfServeEmailConfirmationCodeIsSuccess ||
        String(confirmationCode).length === 6;

    const buttonDisabled =
        !validConfirmationCode ||
        !selfServeNewEntityConfigValid ||
        !Object.keys(selfServeInboundTreasuries).length ||
        !validItems ||
        !selfServeNewUserValid;

    const buttonLoading =
        postSelfServeEmailConfirmationCodeIsPending ||
        postSelfServeCreateAccountIsPending;

    let buttonCopy = "Confirm my email";
    if (postSelfServeEmailConfirmationCodeIsPending) {
        buttonCopy = "Sending confirmation code";
    }

    if (postSelfServeEmailConfirmationCodeIsSuccess) {
        buttonCopy = "Get my payment link";
    }

    if (postSelfServeCreateAccountIsPending) {
        buttonCopy = "Creating account";
    }

    useEffect(() => {
        if (postSelfServeCreateAccountData) {
            onSuccess(postSelfServeCreateAccountData);
        }
    }, [postSelfServeCreateAccountData, onSuccess]);

    return (
        <Form onSubmit={onSubmit}>
            {postSelfServeEmailConfirmationCodeIsSuccess && (
                <SubSection
                    title="Confirm your email"
                    description={
                        <>
                            <strong>Please do not close this page</strong>. You
                            have been emailed a confirmation code to add to the
                            field below.{" "}
                        </>
                    }
                    spacing={[Spacing.xl, Spacing.lg]}
                >
                    <S.Code spacing={[Spacing.md, Spacing.none]}>
                        <Label htmlFor="confirmationCode">
                            Confirmation Code
                        </Label>

                        <Input
                            type="text"
                            value={confirmationCode}
                            placeholder="******"
                            onChange={(event) =>
                                setConfirmationCode(Number(event.target.value))
                            }
                        />
                        <Hint>
                            Sent at{" "}
                            {new Date(
                                postSelfServeEmailConfirmationCodeSubmittedAt
                            ).toLocaleTimeString("en-US")}
                            <span>&mdash;</span>
                            <Anchor onClick={sendConfirmationCode}>
                                Resend confirmation code
                            </Anchor>
                        </Hint>
                    </S.Code>
                </SubSection>
            )}
            <Button
                type="submit"
                disabled={buttonDisabled}
                loading={buttonLoading}
            >
                {buttonCopy}
            </Button>
        </Form>
    );
}

export default forwardRef(SelfServeSubmitForm);
