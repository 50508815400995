import styled from "styled-components";
import { sizes as s } from "global-style";
import colors from "theme/colors";
import Title from "components/Title";

export const TransactionSenderAndReceiver = styled.section`
    display: flex;
    gap: 2rem;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: ${s.md}) {
        flex-direction: row;
    }
`;
export const Header = styled(Title)`
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: ${colors.text};
`;

export const WalletContainer = styled.div`
    width: 100%;
    flex: 1;
`;

export const ArrowContainer = styled.div`
    padding-top: 2.25rem;
    display: none;
    @media screen and (min-width: ${s.md}) {
        display: inline-block;
    }
`;

export const AllowanceAndBalanceContainer = styled.div`
    margin-top: 0.5rem;
`;

export const Row = styled.div`
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 2fr;
    margin-top: 0.5rem;
`;

export const AmountInUsd = styled.span`
    color: ${colors.lightText};
    margin-left: 1rem;
`;

export const WalletDetails = styled.div`
    padding: 1rem 1.25rem;
    border: 1px solid ${colors.borderMedium};
    border-radius: 0.5rem;
    word-break: break-all;
`;
