import * as S from "./style";
import { useState, useMemo, useEffect, ReactNode } from "react";
import { useMatches, Outlet, RouteObject, useNavigate } from "react-router-dom";
import { getBooleanFromFnOrBool } from "utils/booleans";
import { isArrayOfEnumValues } from "utils/arrays";
import { HasRoleFunction, UserRole, useUser } from "context/User";
import { useSession } from "context/Session";
import { useHistoryState } from "hooks/useHistoryState";
import { DataLoaderStatus } from "hooks/useFetch";
import Menu, { MenuData, MenuType } from "components/Menu";
import WalletConnect from "components/WalletConnect";
import User from "components/icons/User";
import ErrorMessage from "components/ErrorMessage";
import Loading from "components/Loading";
import Tooltip from "components/Tooltip";
import Question from "components/icons/Question";
import Hamburger from "components/icons/Hamburger";
import Anchor from "components/Anchor";
import colors from "theme/colors";
import { ButtonVariants } from "components/Button";
import { useWalletContext } from "context/WalletContext";

interface DashboardProps {
    menus: MenuData[];
    theme?: "light" | "dark";
    wallet?: boolean;
    dataLoaders: (() => any)[];
    readOnly?: boolean | (() => boolean);
}

interface MenusByType {
    main: MenuData[];
    bottom: MenuData[];
}

interface DataLoader {
    error: string[];
    loading: boolean[];
}

const Dashboard = ({
    menus,
    theme = `light`,
    wallet: walletRequiredForLogin = false,
    dataLoaders,
    readOnly = false,
}: DashboardProps) => {
    const navigate = useNavigate();
    const { walletsAvailable, handleDisconnectWallet, walletConnected } =
        useWalletContext();

    const {
        killSession,
        sessionEmail,
        hasRole: hasRoleWeb3,
        hasRoles: hasRolesWeb3,
        getSessionToken: getSessionTokenWeb3,
    } = useSession();

    const userData = useUser();

    // Handles error messaging from redirects
    useHistoryState(true);

    // Sub in functions to bridge Session/User
    if (userData.customer) {
        const web3Roles: HasRoleFunction = (roles) => {
            if (Array.isArray(roles)) return hasRolesWeb3(roles);
            return hasRoleWeb3(roles);
        };
        userData.logout = killSession;
        userData.hasRole = web3Roles;
        userData.getSessionToken = getSessionTokenWeb3;
        userData.getEmail = () => sessionEmail;
    } else {
        const web2Logout = userData.logout;
        userData.logout = () => {
            web2Logout.call(userData);
            navigate(`/`); // Move this back to handleLogout when Session is removed
        };
    }

    const {
        userReady,
        logout,
        hasRole,
        getDelegatedSigning,
        getSessionToken,
        getCompanyName,
        getEmail,
    } = userData;

    const username = getCompanyName();
    const [heading, setHeading] = useState<ReactNode>(``);
    const [tip, setTip] = useState<ReactNode>(``);
    const [help, setHelp] = useState(``);
    const [walletRequiredForRoute, setWalletRequiredForRoute] = useState(false);
    const [description, setDescription] = useState(``);
    const [mainMenus, setMainMenus] = useState(menus);
    const [menuOpen, setMenuOpen] = useState(false);

    // Determine if this user is viewing in "read only" mode
    readOnly = getBooleanFromFnOrBool(readOnly);

    const routeVariables: RouteObject[] = useMatches();

    useEffect(() => {
        if (!routeVariables || routeVariables.length < 1) return;

        // Determine the last heading in the route-tree
        const lastHeading = routeVariables.findLast(
            ({ handle }: any): boolean => handle?.heading
        );
        setHeading(lastHeading?.handle.heading || ``);
        setTip(lastHeading?.handle.tip || ``);

        // Determine the last page description in the route-tree
        const lastDesc = routeVariables.findLast(
            ({ handle }: any): boolean => handle?.description
        );
        setDescription(lastDesc?.handle.description || ``);

        // Determine the last help link in the route-tree
        const lastHelpLink = routeVariables.findLast(
            ({ handle }: any): boolean => handle?.help
        );
        setHelp(lastHelpLink?.handle.help || ``);

        // Does the last route/page in the route-tree require a wallet "connect" button?
        const lastWallet = routeVariables.findLast(
            ({ handle }: any): boolean => handle?.wallet
        );

        if (getDelegatedSigning()) {
            setWalletRequiredForRoute(false);
        } else {
            setWalletRequiredForRoute(
                isArrayOfEnumValues(lastWallet?.handle?.wallet, UserRole)
                    ? hasRole(lastWallet?.handle.wallet) // UserRole[]
                    : lastWallet?.handle.wallet // boolean | undefined
            );
        }
    }, [routeVariables, getDelegatedSigning, hasRole]);

    const menusByType = useMemo(
        () =>
            mainMenus.reduce<MenusByType>(
                (allMenus, m) =>
                    m.type === MenuType.Main
                        ? {
                              bottom: allMenus.bottom,
                              main: [...allMenus.main, m],
                          }
                        : m.type === MenuType.Bottom
                        ? {
                              main: allMenus.main,
                              bottom: [...allMenus.bottom, m],
                          }
                        : allMenus,
                {
                    main: [],
                    bottom: [],
                }
            ),
        [mainMenus]
    );

    // "dataLoaders" are passed to control screen state between loading/error/success
    const data = dataLoaders.reduce<DataLoader>(
        (loaders: DataLoader, dataLoader: () => DataLoaderStatus) => {
            const { error, loading } = dataLoader();
            if (error) loaders.error.push(error);
            if (loading) loaders.loading.push(loading);
            return loaders;
        },
        { error: [], loading: [] }
    );

    const handleCloseMenu = () => {
        setMenuOpen(false);
    };

    const handleToggleMenu = () => {
        setMenuOpen((m) => !m);
    };

    const handleLogout = () => {
        logout();

        if (!walletRequiredForLogin) return;
        // [ ] Unclear if individual "handleDisconnectWallet" is still necessary here
        // walletsAvailable.forEach(({ label }: { label: string }) =>
        //     handleDisconnectWallet({ label: label })
        // );
        handleDisconnectWallet();
    };

    const handleLogin = () => {
        navigate(`/login`);
    };

    const isWalletRequired = walletRequiredForLogin || walletRequiredForRoute;

    return (
        <S.Dashboard theme={theme}>
            <S.Navigation role="menubar" open={menuOpen}>
                <S.EnvBadge />
                {readOnly && (
                    <S.RolesBadge variant="gray" uppercase border>
                        View Only
                    </S.RolesBadge>
                )}
                <S.LoopLogoSmVp href="/" full theme={theme} />
                <S.LoopLogoLgVp href="/" tall full theme={theme} />
                <S.NavBtn
                    id="main-nav-button"
                    aria-haspopup="true"
                    aria-controls="main-nav"
                    noStyles
                    onClick={handleToggleMenu}
                >
                    <Hamburger open={menuOpen} color={colors.white} />
                </S.NavBtn>
                <S.Menus
                    open={menuOpen}
                    id="main-nav"
                    role="menu"
                    aria-labelledby="main-nav-button"
                    aria-label="Navigation menu"
                >
                    {menusByType.main.map((m: MenuData) => (
                        <Menu
                            key={m.id}
                            data={m}
                            onStaticLinkClick={handleCloseMenu}
                            onInternalLinkClick={handleCloseMenu}
                            checkUsersRole={hasRole}
                        />
                    ))}
                    <S.BottomMenus>
                        <S.Sticky>
                            {menusByType.bottom.map((m: MenuData) => (
                                <Menu
                                    key={m.id}
                                    data={m}
                                    onStaticLinkClick={handleCloseMenu}
                                    onInternalLinkClick={handleCloseMenu}
                                />
                            ))}
                            <S.UserAndLogout>
                                {username && (
                                    <S.Username>
                                        <S.DeadLink>
                                            <User
                                                height={`1.25rem`}
                                                width={`1.25rem`}
                                                fill="inherit"
                                                name={username}
                                            />
                                            {username}
                                        </S.DeadLink>
                                    </S.Username>
                                )}
                                {getSessionToken() ? (
                                    <S.LoginLogout
                                        variant={ButtonVariants.PrimaryOutlined}
                                        onClick={handleLogout}
                                        $theme={theme}
                                        full
                                    >
                                        Log out
                                    </S.LoginLogout>
                                ) : (
                                    <S.LoginLogout
                                        full
                                        onClick={handleLogin}
                                        $theme={theme}
                                        variant={ButtonVariants.PrimaryOutlined}
                                    >
                                        Log in
                                    </S.LoginLogout>
                                )}
                            </S.UserAndLogout>
                        </S.Sticky>
                    </S.BottomMenus>
                </S.Menus>
            </S.Navigation>
            <S.Content>
                <S.Header>
                    {heading && (
                        <S.Greeting level="h1">
                            {heading}{" "}
                            {tip && (
                                <Tooltip title={tip} placement="bottom" noWrap>
                                    <span
                                        style={{
                                            lineHeight: `1`,
                                            verticalAlign: `text-top`,
                                        }}
                                    >
                                        <Question height="1rem" width="1rem" />
                                    </span>
                                </Tooltip>
                            )}
                        </S.Greeting>
                    )}
                    <S.TopRight>
                        {!readOnly && isWalletRequired && <WalletConnect />}
                        {getEmail() && <S.Email>{getEmail()}</S.Email>}
                    </S.TopRight>

                    {help && (
                        <Tooltip title="Get help with this page">
                            <S.Help href={help} target="_blank" noStyles>
                                <Question height="1rem" width="1rem" />
                            </S.Help>
                        </Tooltip>
                    )}
                    {description && (
                        <S.Description>{description}</S.Description>
                    )}
                </S.Header>

                {walletRequiredForLogin && !walletConnected?.address ? (
                    <ErrorMessage
                        msg={`A connected wallet is required to view this page`}
                    >
                        <Anchor href="/login">Click here to login</Anchor> if
                        you are not redirected automatically.
                    </ErrorMessage>
                ) : data.error.length > 0 ? (
                    <ErrorMessage msg="Something went wrong while loading data.">
                        Log out or refresh to try again.
                    </ErrorMessage>
                ) : data.loading.includes(true) || !userReady ? (
                    <Loading />
                ) : (
                    <Outlet />
                )}
            </S.Content>
        </S.Dashboard>
    );
};

export default Dashboard;
