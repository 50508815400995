import * as S from "./style";
import { useRef, useState } from "react";
import { apiServerUrl, frequencyTypes } from "default-variables";
import { toCoin } from "utils/financial";
import { regex, regexToPattern } from "utils/regex";
import { useNotificationQueue } from "context/NotificationQueue";
import { useSwapForm } from "recurring/context/SwapForm";
import { CardSection } from "components/Card";
import Button from "components/Button";
import InputText from "components/InputText";
import { NotificationType } from "components/Notification";
import { useWalletContext } from "context/WalletContext";

const SummaryAndEmail = ({ contractAddress, networkId, walletAddress }) => {
    // FUTURE UPGRADES:
    // [ ] <abbr> should be a component that will do a lookup
    // [ ] <data> should be a component
    // [ ] <time> should be a component

    const [sendingEmail, setSendingEmail] = useState({
        sending: false,
        complete: false,
    });
    const emailRef = useRef(null);

    const { exchange, frequency } = useSwapForm();
    const { addNotification } = useNotificationQueue();
    const { getSignedMessage } = useWalletContext();

    const handleSendEmail = async (event) => {
        event.preventDefault();

        if (!emailRef.current.value || !emailRef.current.validity.valid) return;

        // Lock the UI
        setSendingEmail({ ...sendingEmail, sending: true });

        const body = {
            email: emailRef.current.value,
            contractAddress: contractAddress,
            network: parseInt(networkId, 16),
        };

        let header;
        try {
            header = {
                "Content-Type": "application/json",
                Authorization: await getSignedMessage(),
            };
        } catch (error) {
            setSendingEmail({ sending: false, complete: false });
            addNotification({
                msg: `There was a problem getting authorization to store your email.`,
                type: NotificationType.ERROR,
            });
            throw new Error(
                `There was a problem getting authorization to store your email.`
            );
        }

        const response = await fetch(
            `${apiServerUrl}/wallet/${walletAddress}`,
            {
                method: `POST`,
                headers: header,
                body: JSON.stringify(body),
            }
        );

        if (response.status === 200) {
            setSendingEmail({ sending: false, complete: true });
        } else {
            setSendingEmail({ sending: false, complete: false });
            addNotification({
                msg: `Something went wrong while storing your email address.`,
                type: NotificationType.ERROR,
            });
            throw Error(
                `Something went wrong while storing your email address.`
            );
        }

        return response;
    };

    const frqLbl = frequencyTypes
        .find(({ value }) => value === frequency)
        ?.label.toLowerCase();

    return (
        <S.SummaryAndEmail>
            <S.Congratulations>
                <h1>You are subscribed!</h1>
                <p style={{ marginBlock: 0 }}>
                    A swap of{" "}
                    <data value={exchange.sell.amount}>
                        {toCoin(Number(exchange.sell.amount))}
                    </data>{" "}
                    <abbr>{exchange.sell.currency}</abbr> for{" "}
                    <abbr>{exchange.buy.currency}</abbr> was successful.
                </p>
                <p style={{ marginBlock: 0 }}>
                    A recurring swap of{" "}
                    <data value={exchange.sell.amount}>
                        {toCoin(Number(exchange.sell.amount))}
                    </data>{" "}
                    <abbr>{exchange.sell.currency}</abbr> for{" "}
                    <abbr>{exchange.buy.currency}</abbr> will take place every{" "}
                    <time>{frqLbl}</time>.
                </p>
            </S.Congratulations>

            {sendingEmail.complete ? (
                <S.EmailSuccess>
                    <div>
                        <h2>
                            <svg
                                width="16"
                                height="12"
                                viewBox="0 0 16 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14.7099 1.20986C14.617 1.11613 14.5064 1.04174 14.3845 0.990969C14.2627 0.940201 14.132 0.914062 13.9999 0.914062C13.8679 0.914062 13.7372 0.940201 13.6154 0.990969C13.4935 1.04174 13.3829 1.11613 13.29 1.20986L5.83995 8.66986L2.70995 5.52986C2.61343 5.43662 2.49949 5.36331 2.37463 5.3141C2.24978 5.2649 2.11645 5.24077 1.98227 5.24309C1.84809 5.24541 1.71568 5.27414 1.5926 5.32763C1.46953 5.38113 1.35819 5.45834 1.26495 5.55486C1.17171 5.65138 1.0984 5.76532 1.04919 5.89018C0.999989 6.01503 0.975859 6.14836 0.97818 6.28254C0.980502 6.41672 1.00923 6.54913 1.06272 6.67221C1.11622 6.79529 1.19343 6.90662 1.28995 6.99986L5.12995 10.8399C5.22291 10.9336 5.33351 11.008 5.45537 11.0588C5.57723 11.1095 5.70794 11.1357 5.83995 11.1357C5.97196 11.1357 6.10267 11.1095 6.22453 11.0588C6.34639 11.008 6.45699 10.9336 6.54995 10.8399L14.7099 2.67986C14.8115 2.58622 14.8925 2.47257 14.9479 2.34607C15.0033 2.21957 15.0319 2.08296 15.0319 1.94486C15.0319 1.80676 15.0033 1.67015 14.9479 1.54365C14.8925 1.41715 14.8115 1.3035 14.7099 1.20986Z"
                                    fill="#5CC689"
                                />
                            </svg>{" "}
                            You are all set
                        </h2>
                        <p>
                            You will receive a reminder 3 days before the next
                            swap.
                        </p>
                    </div>
                </S.EmailSuccess>
            ) : (
                <CardSection>
                    <h2>Want reminders?</h2>
                    <p>
                        Provide an email below and we'll notify you before each
                        swap.
                    </p>
                    <S.CollectEmail as="form" onSubmit={handleSendEmail}>
                        <InputText
                            value=""
                            label="Email address"
                            ref={emailRef}
                            type="email"
                            pattern={regexToPattern(regex.email)}
                            placeholder=""
                            disabled={sendingEmail.sending}
                            button={{
                                type: `submit`,
                                label: (
                                    <S.BtnLabelImg
                                        width="18"
                                        height="21"
                                        viewBox="0 0 15 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12.7125 6.9901L3.96253 1.7401C3.61721 1.53385 3.22729 1.46043 2.84593 1.52983C2.46457 1.59924 2.11032 1.8081 1.83149 2.12793C1.55266 2.44777 1.36282 2.86301 1.28784 3.31705C1.21287 3.7711 1.25641 4.24184 1.41253 4.6651L2.91253 8.6926C2.94657 8.78997 2.9641 8.8945 2.9641 9.0001C2.9641 9.10569 2.94657 9.21022 2.91253 9.3076L1.41253 13.3351C1.28547 13.6776 1.23175 14.0528 1.25627 14.4266C1.28078 14.8004 1.38275 15.1609 1.5529 15.4754C1.72305 15.7898 1.95599 16.0483 2.23055 16.2273C2.50511 16.4062 2.81258 16.5 3.12503 16.5001C3.41768 16.4966 3.70596 16.4146 3.96878 16.2601L12.7188 11.0101C13.0292 10.8227 13.29 10.5356 13.4723 10.1807C13.6546 9.82574 13.7512 9.41702 13.7512 9.0001C13.7512 8.58318 13.6546 8.17446 13.4723 7.81954C13.29 7.46462 13.0292 7.17746 12.7188 6.9901H12.7125ZM12.1563 9.6676L3.40628 14.9176C3.29138 14.9838 3.16236 15.0063 3.03652 14.982C2.91068 14.9577 2.79404 14.8878 2.70223 14.7817C2.61042 14.6756 2.54783 14.5384 2.52286 14.3884C2.4979 14.2384 2.51174 14.0829 2.56253 13.9426L4.05628 9.9151C4.07562 9.86132 4.09231 9.80622 4.10628 9.7501H8.41253C8.57829 9.7501 8.73726 9.67108 8.85447 9.53043C8.97168 9.38977 9.03753 9.19901 9.03753 9.0001C9.03753 8.80118 8.97168 8.61042 8.85447 8.46977C8.73726 8.32911 8.57829 8.2501 8.41253 8.2501H4.10628C4.09231 8.19397 4.07562 8.13888 4.05628 8.0851L2.56253 4.0576C2.51174 3.91732 2.4979 3.76176 2.52286 3.61177C2.54783 3.46179 2.61042 3.32455 2.70223 3.21846C2.79404 3.11237 2.91068 3.04249 3.03652 3.01821C3.16236 2.99393 3.29138 3.01639 3.40628 3.0826L12.1563 8.3326C12.2587 8.39553 12.3446 8.49116 12.4046 8.60893C12.4646 8.7267 12.4963 8.86206 12.4963 9.0001C12.4963 9.13813 12.4646 9.27349 12.4046 9.39126C12.3446 9.50904 12.2587 9.60466 12.1563 9.6676Z"
                                            fill="white"
                                        />
                                    </S.BtnLabelImg>
                                ),
                                square: true,
                            }}
                        />
                    </S.CollectEmail>
                </CardSection>
            )}

            <CardSection>
                <h2>Need to manage your recurring swaps?</h2>
                <p>
                    Head over to the{" "}
                    <a
                        href={import.meta.env.VITE_DASHBOARD_URL}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Loop portal
                    </a>{" "}
                    to see and manage all of your swaps.
                </p>
            </CardSection>
            <CardSection>
                <h2>Set up another swap!</h2>
                <p>
                    Wallets can set up multiple recurring swaps. Click below to
                    create another.
                </p>
            </CardSection>
            <S.Footer>
                <Button slim href="/">
                    Create another swap
                </Button>
            </S.Footer>
        </S.SummaryAndEmail>
    );
};

export default SummaryAndEmail;
