import * as S from "./style";
import safeLogo from "assets/img/logos/safe-wallet.svg";
import { ReactNode, useState, useEffect } from "react";
import Tooltip from "components/Tooltip";
import Anchor from "components/Anchor";
import Badge, { Density } from "components/Badge";
import Button, { ButtonProps } from "components/Button";
import DynamicAddressDisplay from "components/DynamicAddressDisplay";
import InfoToolTip from "components/InfoToolTip";
import Pencil from "components/icons/Pencil";
import X from "components/icons/X";
import colors from "theme/colors";
import { useWalletContext } from "context/WalletContext";
import useSafesConnected from "hooks/useSafesConnected";

interface WalletConnectPanelProps extends Omit<ButtonProps, "children"> {
    children?: ReactNode;
    useCheckoutWalletSwitch?: boolean;
}

const WalletConnectPanel = ({
    children = "Connect",
    useCheckoutWalletSwitch = false,
    ...props
}: WalletConnectPanelProps) => {
    const {
        handleConnectWallet,
        handleDisconnectWallet,
        isWalletConnecting,
        walletConnected,
        networkConnected,
        setProxyWallet,
    } = useWalletContext();

    const { safesConnected } = useSafesConnected();

    const handleConnectClick = () => {
        handleConnectWallet();
    };

    const handleEditClick = () => {
        if (useCheckoutWalletSwitch) {
            // Wallet switcher lives at checkout's root url
            window.open(import.meta.env.VITE_CHECKOUT_URL);
        } else {
            handleConnectWallet();
        }
    };

    const handleDisconnectClick = () => {
        // [ ] Disconnect no longer takes parameters, right? Is it worth individual disconnection?
        handleDisconnectWallet(/* { label: walletConnected.label } */);
        // [ ] These have to be dealt with
        window.localStorage.removeItem("walletconnect");
        window.localStorage.removeItem("WALLETCONNECT_DEEPLINK_CHOICE");
    };

    const handleClickEoaWallet = () => {
        if (!walletConnected?.proxyFor) return;
        setProxyWallet(null);
    };

    const handleClickSafeWallet = (safeWallet: string) => {
        setProxyWallet(
            walletConnected?.proxyFor?.toLowerCase() ===
                safeWallet?.toLowerCase()
                ? null
                : safeWallet
        );
    };

    const handleClickEoaUnproxy = () => {
        setProxyWallet(null);
    };

    return !walletConnected ? (
        <Button
            {...props}
            title="Connect Wallet"
            onClick={handleConnectClick}
            loading={isWalletConnecting}
        >
            {isWalletConnecting ? "Connecting..." : children}
        </Button>
    ) : (
        <S.WalletConnectPanel>
            <S.Connected>Connected:</S.Connected>
            {walletConnected?.icon && (
                <Tooltip title={walletConnected.label} placement="top">
                    <S.WalletIcon
                        src={walletConnected.icon}
                        alt={`${walletConnected.label} logo`}
                        title={walletConnected.label}
                    />
                </Tooltip>
            )}
            <S.MainAddress>
                <DynamicAddressDisplay
                    address={walletConnected.address}
                    networkId={networkConnected?.networkId}
                    monospace
                    shorten
                    placement="top"
                    anchor={false}
                    underlined={false}
                    style={
                        safesConnected && !walletConnected.proxyFor
                            ? { color: colors.primary }
                            : undefined
                    }
                    onClick={
                        walletConnected.proxyFor
                            ? handleClickEoaWallet
                            : undefined
                    }
                />
                {safesConnected && !walletConnected.proxyFor && (
                    <Badge variant="purple" density={Density.COMPACT}>
                        active
                    </Badge>
                )}
                {safesConnected && walletConnected.proxyFor && (
                    <S.Proxying
                        variant="orange"
                        density={Density.COMPACT}
                        onClick={handleClickEoaUnproxy}
                    >
                        proxy
                    </S.Proxying>
                )}
            </S.MainAddress>
            <S.EditWallet>
                <Tooltip title="Change the connected wallet" placement="top">
                    <Button noStyles onClick={handleEditClick}>
                        <Pencil height="1rem" width="1rem" />
                    </Button>
                </Tooltip>
            </S.EditWallet>
            <S.DisconnectWallet>
                <Tooltip title="Disconnect wallet" placement="top">
                    <Button noStyles onClick={handleDisconnectClick}>
                        <X height="1rem" width="1rem" />
                    </Button>
                </Tooltip>
            </S.DisconnectWallet>

            {safesConnected && (
                <S.SafeWallets>
                    <h3>
                        <Anchor
                            href="https://safe.global/"
                            underlined={false}
                            inheritColor={true}
                        >
                            <S.SafeLogo src={safeLogo} alt="Safe (wallet)" />
                            Safe
                        </Anchor>{" "}
                        wallets available{" "}
                        <InfoToolTip title="Your EOA wallet can act as a proxy for any Safe wallet it represents. Click a Safe address to activate it. Click again to switch back to your EOA wallet." />
                    </h3>
                    <S.Safes>
                        {safesConnected?.map(({ address: safeAddress }) => (
                            <S.Address
                                as={"li"}
                                key={safeAddress}
                                data-proxying={
                                    walletConnected.proxyFor?.toLowerCase() ===
                                    safeAddress.toLowerCase()
                                }
                                onClick={() =>
                                    handleClickSafeWallet(safeAddress)
                                }
                                tabIndex="0"
                            >
                                <DynamicAddressDisplay
                                    shorten
                                    address={safeAddress}
                                    networkId={networkConnected?.networkId}
                                    anchor={false}
                                    placement="right"
                                />
                                {walletConnected.proxyFor?.toLowerCase() ===
                                    safeAddress.toLowerCase() && (
                                    <>
                                        {` `}
                                        <Badge
                                            variant="purple"
                                            density={Density.COMPACT}
                                        >
                                            active
                                        </Badge>
                                    </>
                                )}
                            </S.Address>
                        ))}
                    </S.Safes>
                </S.SafeWallets>
            )}
        </S.WalletConnectPanel>
    );
};

export default WalletConnectPanel;
