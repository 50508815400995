import { useCallback } from "react";
import { PublicKey } from "@solana/web3.js";
import { sign, verify } from "web3-token";
import * as nacl from "tweetnacl";
import { isUint8Array } from "utils/arrays";
import { EvmWalletSigner, SolWalletSigner } from "types/common";
import { PrimaryWalletAccount } from "./useWalletConnected";

export interface WalletSignerToken {
    signature: string;
    pendingMsgHash: string;
    timeStamp: number;
}

const getSolSignedMessage = async (
    signer: SolWalletSigner,
    message: string
) => {
    const encodedMessage = new TextEncoder().encode(message);

    const signature = await signer.signMessage(encodedMessage);

    if (!signature) {
        throw new Error(`signMessage() returned undefined`);
    }

    // ICoinbaseSolanaSigner will return a Uint8Array from signMessage
    return Buffer.from(
        isUint8Array(signature) ? signature : signature.signature
    ).toString(`base64`);
};

const getEvmSignedMessage = async (
    signer: EvmWalletSigner,
    message: string
) => {
    return await sign(
        async (msg) => {
            const signature = await signer.signMessage(msg);

            if (!signature) {
                throw new Error(`signMessage() returned undefined`);
            }
            return signature;
        },
        {
            statement: message,
            domain: "loopcrypto.xyz",
        }
    );
};

const useWalletSignature = (wallet: PrimaryWalletAccount | null) => {
    const message = `I accept Loop Crypto Inc's Terms of Service`;

    const generateTransferSignatureToken = useCallback(
        async ({
            contractAddress,
            contractNetworkId,
            invoiceId,
            toAddress,
            fromAddress,
            token,
            amount,
            usd,
        }: any) => {
            if (!wallet) return false;
            const signer = wallet.signer;

            const domain = {
                name: "LoopVariableRatesContract",
                version: "1",
                chainId: contractNetworkId,
                verifyingContract: contractAddress,
            };

            const transfer = {
                invoiceId,
                from: fromAddress,
                to: toAddress,
                token,
                amount,
                usd,
            };

            const data = JSON.stringify({
                domain,
                // Defining the message signing data content.
                message: transfer,

                // Refers to the keys of the *types* object below.
                primaryType: "Transfer",
                types: {
                    // TODO: Clarify if EIP712Domain refers to the domain the contract is hosted on
                    EIP712Domain: [
                        { name: "name", type: "string" },
                        { name: "version", type: "string" },
                        { name: "chainId", type: "uint256" },
                        { name: "verifyingContract", type: "address" },
                    ],
                    // Not an EIP712Domain definition
                    Transfer: [
                        { name: "invoiceId", type: "string" },
                        { name: "from", type: "address" },
                        { name: "to", type: "address" },
                        { name: "token", type: "address" },
                        { name: "amount", type: "uint256" },
                        { name: "usd", type: "bool" },
                    ],
                },
            });

            if (wallet.chain === "EVM") {
                // [ ] This needs to be fixed for Solana
                const from = wallet.address;
                var params = [from, data];
                var method = "eth_signTypedData_v3";

                return await (signer as EvmWalletSigner).provider.send(
                    method,
                    params
                );
            }
        },
        [wallet]
    );

    const getSignedMessage = useCallback(async (): Promise<
        WalletSignerToken | false
    > => {
        if (!wallet) return false;

        try {
            const signature =
                wallet.chain === "SOL"
                    ? await getSolSignedMessage(wallet.signer, message)
                    : await getEvmSignedMessage(wallet.signer, message);

            return {
                signature,
                pendingMsgHash: "",
                timeStamp: Date.now(),
            };
        } catch (error) {
            console.error(`Failed to generate signer token`, error);
            return false;
        }
    }, [wallet, message]);

    const verifySignedMessage = useCallback(
        (signature: string) => {
            if (!wallet) return;

            try {
                if (wallet.chain === "SOL") {
                    const pubKey = new PublicKey(wallet.address);
                    const messageBytes = new TextEncoder().encode(message);
                    const signatureBytes = Buffer.from(signature, `base64`);

                    return nacl.sign.detached.verify(
                        messageBytes,
                        signatureBytes,
                        pubKey.toBytes()
                    );
                }

                // EVM
                const { address } = verify(signature);

                if (address?.toLowerCase() === wallet.address.toLowerCase()) {
                    return true;
                }
                return false;
            } catch (error) {
                throw new Error(`Unable to verify signature: ${error}`);
            }
        },
        [wallet, message]
    );

    return {
        generateTransferSignatureToken,
        getSignedMessage,
        verifySignedMessage,
    };
};

export default useWalletSignature;
