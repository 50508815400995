import { ethers, BigNumber } from "ethers";
import { ContractToken } from "admin/types";
import factoryAbi from "abis/abi/LoopFactory.json";
import subscriptionsAbi from "abis/abi/Subscriptions.json";
import { useWalletContext } from "context/WalletContext";
import { EvmWalletSigner } from "types/common";

interface SubscriptionItem {
    name: string;
    frequency: number;
    amount: number; // USD
    stakeAmount: number; // USD
    fee: number;
}

const {
    utils: { parseUnits },
} = ethers;

const useSubscriptionContract = () => {
    // TODO type
    const { walletConnected } = useWalletContext();

    // TODO create generic 'getAbi(abiFileName)' fxn.
    // const getFactoryAbi = async () => {
    //     let abi;
    //     try {
    //         abi = await import(`../abis/abi/LoopFactory.json`).then(
    //             (module) => module.default
    //         );
    //     } catch (errMsg) {
    //         throw new Error(
    //             `There was a problem loading the LoopFactory contract's ABI: ${errMsg}`
    //         );
    //     }

    //     return abi;
    // };

    // const getAbi = async () => {
    //     let abi;
    //     try {
    //         abi = await import(`../abis/abi/Subscriptions.json`).then(
    //             (module) => module.default
    //         );
    //     } catch (errMsg) {
    //         throw new Error(
    //             `There was a problem loading the Subscription contract's ABI: ${errMsg}`
    //         );
    //     }

    //     return abi;
    // };

    const getItem = async (itemName: string, contractAddr: string) => {
        // const abi: any = await getAbi(); // TODO type

        const contract = new ethers.Contract(
            contractAddr,
            subscriptionsAbi,
            // abi,
            walletConnected?.signer as EvmWalletSigner
        );

        let item;
        try {
            item = await contract.subscriptionTiers(itemName.toLowerCase());
        } catch (errMsg) {
            throw new Error(`Transaction failed before creation: ${errMsg}`);
        }

        return item;
    };

    const createContract = async (
        item: SubscriptionItem,
        acceptedTokens: ContractToken[],
        wETH: string, // Address
        receivableAddr: string, // Address
        factoryAddr: string // Address
    ) => {
        const acceptedTokenAddresses = acceptedTokens.map(
            (token: ContractToken) => token.address
        );
        const chainlinkAggregators = acceptedTokens.map(
            (token: ContractToken) => token.aggregatorAddress
        );

        const factory = new ethers.Contract(
            factoryAddr,
            factoryAbi,
            walletConnected?.signer as EvmWalletSigner
        );

        const subscriptionsInterface = new ethers.utils.Interface(
            subscriptionsAbi
        );
        const paramBytes = subscriptionsInterface.encodeFunctionData(
            `initialize`,
            [
                [item.name.toLowerCase()],
                [item.frequency],
                [parseUnits(item.amount.toString(), 8)],
                [parseUnits(item.stakeAmount.toString(), 8)],
                [parseUnits(item.fee.toString(), 6)],
                acceptedTokenAddresses,
                chainlinkAggregators,
                factoryAddr,
                wETH,
                receivableAddr,
            ]
        );

        let tsx;
        try {
            // `0` represents the `Subscription` template
            tsx = await factory.createProxy(0, paramBytes);
        } catch (errMsg) {
            throw new Error(`Transaction failed at createProxy(): ${errMsg}`);
        }

        let receipt;
        try {
            receipt = await tsx.wait();
        } catch (errMsg) {
            throw new Error(
                `Transaction failed waiting for the transaction: ${errMsg}`
            );
        }

        const newContractDeployedEvent = receipt.events.find(
            (event: any) => event.event === `NewProxyDeployed`
        );

        const newContractAddr =
            newContractDeployedEvent.args.newContractAddress;

        return [receipt, newContractAddr];
    };

    const createItem = async (
        { name, frequency, amount, stakeAmount, fee }: SubscriptionItem,
        contractAddr: string
    ) => {
        const existingItem = await getItem(name, contractAddr);

        if (BigNumber.from(existingItem.frequency).gt(0)) {
            throw new Error(`An item with this name already exists`);
        }

        // const abi: any = await getAbi(); // TODO type

        const contract = new ethers.Contract(
            contractAddr,
            subscriptionsAbi,
            walletConnected?.signer as EvmWalletSigner
        );

        let tsx;
        try {
            tsx = await contract.setSubscriptionTier(
                name.toLowerCase(),
                frequency,
                parseUnits(amount.toString(), 8),
                parseUnits(stakeAmount.toString(), 8),
                parseUnits(fee.toString(), 6)
            );
        } catch (errMsg) {
            throw new Error(
                `Transaction failed at setSubscriptionTier(): ${errMsg}`
            );
        }

        let receipt;
        try {
            receipt = await tsx.wait();
        } catch (errMsg) {
            throw new Error(
                `Transaction failed waiting for the transaction: ${errMsg}`
            );
        }

        return receipt;
    };

    return { getItem, createContract, createItem };
};

export default useSubscriptionContract;
