import * as S from "./style";
import {
    useEffect,
    useState,
    FunctionComponent,
    useCallback,
    useMemo,
} from "react";
import { Company, TransferSource } from "company/types";
import { TransferStatus, ItemCategoryType } from "types/common-enums";
import { toDollar } from "utils/financial";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import { BlockExplorerEntityType } from "utils/urls";
import UpdateTransferToAddressForm from "company/components/transfers/UpdateTransferToAddressForm";
import ArrowRight from "components/icons/ArrowRight";
import Anchor from "components/Anchor";
import DynamicWalletAddressDisplay from "components/DynamicWalletAddress/DynamicWalletAddressDisplay";
import { useEns } from "contexts/EnsProvider";
import { useUser, UserRole } from "context/User";
import { useGetCompanyAgreements } from "company/hooks/useGetCompanyAgreements";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { useGetCompanyItems } from "company/hooks/useGetCompanyItems";
import { useGetNetworks } from "hooks/useGetNetworks";
import { useGetTokensMetadata } from "hooks/useGetTokensMetadata";
import FailedDataFetchingMessage from "company/components/FailedDataFetchingMessage";
import LoadingBox from "components/LoadingBox";
import {
    WalletDataState,
    useWalletInformationProvider,
} from "contexts/WalletInformationProvider";
import { useBalanceAndAllowance } from "hooks/useBalanceAndAllowance";
import Spinner from "components/Spinner";
import { convertTokenToCents } from "utils/exchangeRates";

interface TransactionSenderAndReceiverProps {
    transaction: Company.Transaction;
}

// It's useful to have a <TransactionSenderAndReceiver> from a usability standpoint
const TransactionSenderAndReceiver: FunctionComponent<TransactionSenderAndReceiverProps> =
    ({ transaction }) => {
        // Session & Company data
        const { hasRole } = useUser();
        const { loadBalanceAndAllowance } = useBalanceAndAllowance();

        const { getTokenData } = useWalletInformationProvider();

        const {
            tokens,
            getTokensMetadataIsLoading,
            getTokensMetadataIsError,
            getTokenRate,
        } = useGetTokensMetadata();

        const {
            config: { contracts, entities },
            getCompanyConfigIsLoading,
            getCompanyConfigIsError,
        } = useGetCompanyConfig();

        const { networks, getNetworksIsLoading, getNetworksIsError } =
            useGetNetworks();
        const { items, getCompanyItemsIsLoading, getCompanyItemsIsError } =
            useGetCompanyItems();
        const {
            agreements,
            getCompanyAgreementsIsLoading,
            getCompanyAgreementsIsError,
        } = useGetCompanyAgreements();

        const isLoading =
            getTokensMetadataIsLoading ||
            getCompanyConfigIsLoading ||
            getNetworksIsLoading ||
            getCompanyItemsIsLoading ||
            getCompanyAgreementsIsLoading;

        const isError =
            getTokensMetadataIsError ||
            getCompanyConfigIsError ||
            getNetworksIsError ||
            getCompanyItemsIsError ||
            getCompanyAgreementsIsError;

        const agreement = agreements.find(
            (a) => a.id === transaction?.agreementId
        );
        const token = tokens.find(
            (t) =>
                t.address === agreement?.token &&
                t.networkId === agreement?.networkId
        );

        const network = networks.find(
            (network) => network.id === agreement?.networkId
        );

        const contract = contracts.find(
            (c) => c.networkId === agreement?.networkId
        );

        const item = items.find((item) => agreement?.items.includes(item.id));

        const entity = entities.find(
            (entity) => entity.entityId === agreement?.entity
        );

        const currentWalletData = useMemo(() => {
            if (!agreement || !token) return;
            return getTokenData({
                walletAddress: agreement.sender.wallet,
                tokenAddress: token.address,
                networkId: token.networkId,
            });
        }, [agreement, getTokenData, token]);

        const hasRequiredData =
            agreement !== undefined &&
            contract !== undefined &&
            token !== undefined &&
            network !== undefined &&
            entity !== undefined &&
            item !== undefined;

        // Data & hooks
        const canManage = hasRole(UserRole.COMPANY);

        const [showToAddressForm, setShowToAddressForm] =
            useState<boolean>(false);

        const { getNetworkById } = useAvailableNetworks();

        // This could be problematic if network number `network.hexId` is unavailable
        const availableNetwork = network && getNetworkById(network.hexId);
        const { getEnsRecord } = useEns();

        const fetchAllowanceAndBalance = useCallback(async () => {
            if (!hasRequiredData) return;
            if (!availableNetwork) return;

            await loadBalanceAndAllowance(
                agreement.sender.wallet,
                token.address,
                token.networkId
            );
        }, [
            agreement,
            availableNetwork,
            hasRequiredData,
            loadBalanceAndAllowance,
            token,
        ]);

        useEffect(() => {
            fetchAllowanceAndBalance();
        }, [fetchAllowanceAndBalance]);

        if (isLoading) {
            return <LoadingBox height="20rem" />;
        }

        if (isError) {
            return <FailedDataFetchingMessage />;
        }

        if (!hasRequiredData) {
            return <FailedDataFetchingMessage />;
        }

        const walletDataLoaded =
            currentWalletData &&
            currentWalletData.allowance.state === WalletDataState.LOADED &&
            currentWalletData.balance.state === WalletDataState.LOADED;

        const rate = getTokenRate(token)?.rate;
        const allowanceInUsd =
            rate &&
            walletDataLoaded &&
            toDollar(
                convertTokenToCents(currentWalletData.allowance.amount, rate)
            );

        const balanceInUsd =
            rate &&
            walletDataLoaded &&
            toDollar(
                convertTokenToCents(currentWalletData.balance.amount, rate)
            );

        const isLoopTransfer =
            transaction.sourceId &&
            [TransferSource.Manual, TransferSource.AutoGenerated].includes(
                transaction.sourceId
            );

        const isScheduledOrDraftTransfer = [
            TransferStatus.Scheduled,
            TransferStatus.Draft,
        ].includes(transaction.status as TransferStatus);

        const outboundPayment = item.type === ItemCategoryType.OutboundPayment;
        const canEditToAddress =
            canManage &&
            isLoopTransfer &&
            entity.delegatedSigning &&
            isScheduledOrDraftTransfer &&
            outboundPayment;

        const allowanceLoaded =
            currentWalletData &&
            currentWalletData.allowance.state === WalletDataState.LOADED;
        const allowanceLoading =
            currentWalletData &&
            currentWalletData.allowance.state === WalletDataState.LOADING;

        const balanceLoaded =
            currentWalletData &&
            currentWalletData.balance.state === WalletDataState.LOADED;
        const balanceLoading =
            currentWalletData &&
            currentWalletData.balance.state === WalletDataState.LOADING;

        return (
            <S.TransactionSenderAndReceiver>
                <S.WalletContainer>
                    <S.Header level="h4" bold={false}>
                        Sender
                    </S.Header>
                    <S.WalletDetails>
                        <strong>
                            <DynamicWalletAddressDisplay
                                address={agreement.sender.wallet}
                                ensName={
                                    getEnsRecord(agreement.sender.wallet)?.name
                                }
                                networkId={network?.hexId}
                                shorten
                                type={BlockExplorerEntityType.Address}
                                icon
                                iconFill="currentColor"
                            />
                        </strong>
                        {agreement.sender.email && (
                            <div>{agreement.sender.email}</div>
                        )}
                        <S.AllowanceAndBalanceContainer>
                            <S.Row>
                                <strong>Allowance</strong>{" "}
                                <div>
                                    {allowanceLoaded && (
                                        <>
                                            {currentWalletData.allowance.amount}{" "}
                                            {token.symbol}
                                            <S.AmountInUsd>
                                                ({allowanceInUsd})
                                            </S.AmountInUsd>
                                        </>
                                    )}

                                    {allowanceLoading && <Spinner desaturate />}
                                </div>
                            </S.Row>
                            <S.Row>
                                <strong>Balance</strong>{" "}
                                {currentWalletData && (
                                    <div>
                                        {balanceLoaded && (
                                            <>
                                                {
                                                    currentWalletData.balance
                                                        .amount
                                                }{" "}
                                                {token.symbol}
                                                <S.AmountInUsd>
                                                    ({balanceInUsd})
                                                </S.AmountInUsd>
                                            </>
                                        )}

                                        {balanceLoading && (
                                            <Spinner desaturate />
                                        )}
                                    </div>
                                )}
                            </S.Row>
                        </S.AllowanceAndBalanceContainer>
                    </S.WalletDetails>
                </S.WalletContainer>
                <S.ArrowContainer>
                    <ArrowRight fill="#47464F" />
                </S.ArrowContainer>
                <S.WalletContainer>
                    <S.Header level="h4" bold={false}>
                        Receiver
                    </S.Header>
                    {showToAddressForm ? (
                        <UpdateTransferToAddressForm
                            transaction={transaction}
                            onSuccess={() => setShowToAddressForm(false)}
                            onCancel={() => setShowToAddressForm(false)}
                        />
                    ) : (
                        <S.WalletDetails>
                            <strong>
                                <DynamicWalletAddressDisplay
                                    address={transaction.receiver.wallet}
                                    ensName={
                                        getEnsRecord(
                                            transaction.receiver.wallet
                                        )?.name
                                    }
                                    networkId={network?.hexId}
                                    type={BlockExplorerEntityType.Address}
                                    icon
                                    iconFill="currentColor"
                                    shorten
                                />
                                {canEditToAddress && (
                                    <Anchor
                                        href="#"
                                        onClick={(event) =>
                                            setShowToAddressForm(true)
                                        }
                                    >
                                        Edit receiver
                                    </Anchor>
                                )}
                            </strong>
                            {transaction.receiver.email && (
                                <div>{transaction.receiver.email}</div>
                            )}
                        </S.WalletDetails>
                    )}
                </S.WalletContainer>
            </S.TransactionSenderAndReceiver>
        );
    };

export default TransactionSenderAndReceiver;
