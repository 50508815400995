import * as S from "./style";
import { GlobalStyle } from "global-style";
import { Navigate, Outlet, RouterProvider } from "react-router-dom";
import { typedCreateBrowserRouter } from "utils/router";
import { useHasChildEntities } from "company/hooks/useHasChildEntities";
import { useIsReadOnly } from "company/hooks/useIsReadOnly";
import { NotificationQueueProvider } from "context/NotificationQueue";
import { LoginType, UserProvider, UserRole } from "context/User";
import AccessControl from "components/AccessControl";
import LoginWeb2 from "components/LoginWeb2";
import Dashboard from "components/Dashboard";
import { MenuData, MenuType } from "components/Menu";
import Money from "components/icons/Money";
import Cart from "components/icons/Cart";
import Gear from "components/icons/Gear";
import HomeIcon from "components/icons/Home";
import Users from "components/icons/Users";
import CircledDollar from "components/icons/CircledDollar";
import PlusUser from "components/icons/PlusUser";
import Clipboard from "components/icons/Clipboard";
import Contact from "components/icons/Contact";
import Question from "components/icons/Question";
import TransactionDetails from "company/routes/TransactionDetails";
import Transactions, {
    transactionDataLoader,
} from "company/routes/Transactions";
import Customers from "company/routes/Customers";
import Invoice from "company/routes/Invoice";
import Developer from "company/routes/Developer";
import AuthorizedWallets from "company/routes/AuthorizedWallets";
import Subscriptions from "company/routes/Subscriptions";
import SubscriptionImport from "company/routes/SubscriptionImport";
import SchedulePayments from "company/routes/SchedulePayments";
import Home from "company/routes/Home";
import ErrorMessage from "components/ErrorMessage";
import Entities from "company/routes/Developer/Entities";
import Loading from "components/Loading";
import ActivateUserForm from "components/LoginWeb2/ActivateUserForm";
import LoginForm from "components/LoginWeb2/LoginForm";
import RequestResetForm from "components/LoginWeb2/RequestResetForm";
import ResetPasswordForm from "components/LoginWeb2/ResetPasswordForm";
import RestrictRoute from "components/RestrictRoute";
import OneTimePayments from "company/routes/OneTimePayments";
import { ModalProvider } from "context/ModalProvider";
import CheckoutUrlBuilder from "company/routes/CheckoutUrlBuilder";
import Loop from "components/icons/Loop";
import Clock from "components/icons/Clock";
import QueryProvider from "contexts/QueryProvider";
import { EnsProvider } from "contexts/EnsProvider";
import SelfServe from "company/routes/SelfServe";
import SubscriptionsActive from "company/routes/Subscriptions/SubscriptionsActive";
import SubscriptionsInactive from "company/routes/Subscriptions/SubscriptionsInactive";
import OneTimePaymentsActive from "company/routes/OneTimePayments/OneTimePaymentsActive";
import OneTimePaymentsInactive from "company/routes/OneTimePayments/OneTimePaymentsInactive";
import { WalletInformationProvider } from "contexts/WalletInformationProvider";
// New
import Confirmed from "company/routes/Transactions/components/Confirmed";
import Upcoming from "company/routes/Transactions/components/Upcoming";
import PastDue from "company/routes/Transactions/components/PastDue";
import Canceled from "company/routes/Transactions/components/Canceled";
import Due from "company/routes/Transactions/components/Due";
import WalletContextProvider from "context/WalletContext";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { clarity } from "react-microsoft-clarity";

if (!clarity.hasStarted()) clarity.init("nk4e5a8fli");

const menus: MenuData[] = [
    {
        id: `general-menu`,
        type: MenuType.Main,
        path: `/`,
        items: [
            {
                path: ``,
                icon: (
                    <HomeIcon
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Home"
                    />
                ),
                disableUnlessWithinRoles: [
                    UserRole.COMPANY_READ,
                    UserRole.COMPANY,
                ],
                label: `Home`,
            },
            {
                path: `transactions`,
                icon: (
                    <Money
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Transactions"
                    />
                ),
                disableUnlessWithinRoles: [
                    UserRole.COMPANY_READ,
                    UserRole.COMPANY,
                ],
                label: `Transactions`,
            },
            {
                path: `developer`,
                icon: (
                    <Gear
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Developer"
                    />
                ),
                label: `Developer`,
                displayOnlyForRoles: [UserRole.COMPANY],
                items: [
                    {
                        path: `entities`,
                        label: `Child entities`,
                        isAvailable: useHasChildEntities,
                    },
                ],
            },
        ],
    },
    {
        id: `inbound-payments`,
        heading: `Inbound payment`,
        type: MenuType.Main,
        path: `/`,
        disableUnlessWithinRoles: [UserRole.COMPANY_READ, UserRole.COMPANY],
        items: [
            {
                path: `customers`,
                icon: (
                    <Users
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Customers"
                    />
                ),
                label: `Customers`,
            },
            {
                path: `subscriptions`,
                icon: (
                    <Loop
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Subscriptions"
                    />
                ),
                label: `Subscriptions`,
            },
            {
                path: `payments`,
                icon: (
                    <Cart
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Pay"
                    />
                ),
                label: `One-time`,
            },
        ],
    },
    {
        id: `outbound-payments`,
        heading: `Outbound payment`,
        type: MenuType.Main,
        path: `/`,
        disableUnlessWithinRoles: [UserRole.COMPANY_READ, UserRole.COMPANY],
        items: [
            {
                path: `pay`,
                icon: (
                    <Clock
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Pay"
                    />
                ),
                label: `Schedule payments`,
                displayOnlyForRoles: [UserRole.COMPANY],
            },
            {
                path: `wallets`,
                icon: (
                    <PlusUser
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Authorized wallets"
                    />
                ),
                label: `Authorized wallets`,
            },
        ],
    },
    {
        id: `resources`,
        heading: `Resources`,
        type: MenuType.Bottom,
        items: [
            {
                path: import.meta.env.VITE_LOOP_DOCS_HOME || ``,
                icon: (
                    <Clipboard
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Documentation"
                    />
                ),
                label: `Documentation`,
            },
            {
                path: import.meta.env.VITE_GITBOOK_FAQ || ``,
                icon: (
                    <Question
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Frequently asked questions"
                    />
                ),
                label: `FAQs`,
            },
            {
                path: `mailto:${import.meta.env.VITE_EMAIL_SUPPORT}`,
                icon: (
                    <Contact
                        fill="inherit"
                        height="1.25rem"
                        width="1.25rem"
                        name="Contact us"
                    />
                ),
                label: `Contact Us`,
            },
        ],
    },
];

/* RP: LC-657
    This is a temporary solution to theming our application. Similar temporary
    code exists in other files, search "LC-657" to find other instances */
const theme = `dark`;

const router = typedCreateBrowserRouter(
    [
        {
            path: "/login",
            element: <LoginWeb2 />,
            errorElement: <ErrorMessage>404</ErrorMessage>,
            children: [
                {
                    index: true,
                    element: <LoginForm />,
                },
                {
                    path: "activate/:activationHash",
                    element: <ActivateUserForm />,
                },
                {
                    path: "reset-password/:resetPasswordHash",
                    element: <ResetPasswordForm />,
                },
                {
                    path: "reset-password",
                    element: <RequestResetForm />,
                },
                {
                    path: "*",
                    element: <Navigate to="/login" replace />,
                },
            ],
        },
        {
            path: "/",
            element: (
                <ModalProvider>
                    <Dashboard
                        theme={theme}
                        menus={menus}
                        dataLoaders={[]}
                        readOnly={useIsReadOnly}
                    />
                </ModalProvider>
            ),
            errorElement: <ErrorMessage>404</ErrorMessage>,
            children: [
                {
                    index: true,
                    handle: {
                        name: `Home`,
                        heading: "Home",
                    },
                    element: (
                        <AccessControl
                            rolesWithAccess={[
                                UserRole.COMPANY_READ,
                                UserRole.COMPANY,
                            ]}
                        >
                            <Home />
                        </AccessControl>
                    ),
                },
                {
                    path: "/transactions",
                    handle: {
                        name: `Transactions`,
                        heading: "Transactions",
                    },
                    element: (
                        <AccessControl
                            rolesWithAccess={[
                                UserRole.COMPANY_READ,
                                UserRole.COMPANY,
                            ]}
                        >
                            <Transactions />
                        </AccessControl>
                    ),
                    children: [
                        {
                            index: true,
                            element: <Navigate to="upcoming" replace />,
                        },
                        {
                            path: "confirmed",
                            loader: transactionDataLoader,
                            element: <Confirmed />,
                        },
                        {
                            path: "upcoming",
                            loader: transactionDataLoader,
                            element: <Upcoming />,
                        },
                        {
                            path: "pastdue",
                            loader: transactionDataLoader,
                            element: <PastDue />,
                        },
                        {
                            path: "due",
                            loader: transactionDataLoader,
                            element: <Due />,
                        },
                        {
                            path: "canceled",
                            loader: transactionDataLoader,
                            element: <Canceled />,
                        },
                    ],
                },
                {
                    path: "/transactions/details/:transactionId",
                    handle: {
                        name: `Transaction details`,
                        heading: (
                            <>
                                <S.BackToTx fallback={`/transactions`} />
                                Transactions
                            </>
                        ),
                    },
                    element: (
                        <AccessControl
                            rolesWithAccess={[
                                UserRole.COMPANY_READ,
                                UserRole.COMPANY,
                            ]}
                        >
                            <TransactionDetails />
                        </AccessControl>
                    ),
                },
                {
                    path: "/developer",
                    element: (
                        <AccessControl rolesWithAccess={[UserRole.COMPANY]}>
                            <Outlet />
                        </AccessControl>
                    ),
                    children: [
                        {
                            index: true,
                            handle: { name: `Developer`, heading: "Developer" },
                            element: <Developer />,
                        },
                        {
                            path: "entities",
                            handle: { name: `Entities`, heading: "Entities" },
                            element: (
                                <RestrictRoute
                                    isAvailable={useHasChildEntities}
                                    onFalseNavTo={`/developer`}
                                >
                                    <Entities />
                                </RestrictRoute>
                            ),
                        },
                    ],
                },
                {
                    path: "/invoice",
                    handle: {
                        name: `Invoice`,
                        heading: `Invoice`,
                        tip: (
                            <>
                                <p>
                                    In order to process transfers, an invoice
                                    (also referred to as a "transfer request")
                                    needs to be created
                                    {/* @kirkas When the delegated feature is back in, enable this */}
                                    {/* {delegatedSigning ? `` : `and signed by the signer wallet `} */}
                                    and signed by the signer wallet for each
                                    bill date.
                                </p>
                                <p>
                                    To learn more about automating this process,
                                    refer to our{" "}
                                    <S.LearnMore
                                        href={
                                            import.meta.env
                                                .VITE_LOOP_DOCS_API_ENABLED_CONTRACT_TRANSFER_REQUESTS
                                        }
                                    >
                                        API docs
                                    </S.LearnMore>
                                    .
                                </p>
                            </>
                        ),
                        wallet: true,
                    },
                    element: (
                        <AccessControl rolesWithAccess={[UserRole.COMPANY]}>
                            <Invoice />
                        </AccessControl>
                    ),
                    children: [
                        {
                            path: ":agreementId",
                        },
                    ],
                },
                {
                    path: "/customers",
                    handle: {
                        name: `Customers`,
                        heading: `Customers`,
                        tip: (
                            <>
                                <p>
                                    Customers are wallets that have given
                                    permission to the smart contract to spend.
                                    These are "from" wallets in the payment
                                    request.
                                </p>
                                <S.LearnMore
                                    href={
                                        import.meta.env
                                            .VITE_LOOP_DOCS_TOOLS_COMPANY_ACCOUNTS
                                    }
                                >
                                    Learn more
                                </S.LearnMore>
                            </>
                        ),
                        wallet: [UserRole.COMPANY],
                    },
                    element: (
                        <AccessControl
                            rolesWithAccess={[
                                UserRole.COMPANY_READ,
                                UserRole.COMPANY,
                            ]}
                        >
                            <Customers />
                        </AccessControl>
                    ),
                },
                {
                    path: "/subscriptions",
                    handle: {
                        name: `Subscriptions`,
                        heading: "Subscriptions",
                    },
                    element: (
                        <AccessControl
                            rolesWithAccess={[
                                UserRole.COMPANY_READ,
                                UserRole.COMPANY,
                            ]}
                        >
                            <Subscriptions />
                        </AccessControl>
                    ),
                    children: [
                        {
                            index: true,
                            element: <Navigate to="active" replace />,
                        },
                        {
                            path: "active",
                            element: <SubscriptionsActive />,
                        },
                        {
                            path: "inactive",
                            element: <SubscriptionsInactive />,
                        },
                    ],
                },
                {
                    path: "/subscriptions/charge",
                    handle: {
                        heading: "Charge an existing subscription",
                    },
                    element: (
                        <AccessControl
                            rolesWithAccess={[
                                UserRole.COMPANY_READ,
                                UserRole.COMPANY,
                            ]}
                        >
                            <SubscriptionImport />
                        </AccessControl>
                    ),
                },
                {
                    path: "/payments",
                    handle: {
                        name: `One-time purchase`,
                        heading: "One-time purchase",
                    },
                    element: (
                        <AccessControl
                            rolesWithAccess={[
                                UserRole.COMPANY_READ,
                                UserRole.COMPANY,
                            ]}
                        >
                            <OneTimePayments />
                        </AccessControl>
                    ),
                    children: [
                        {
                            index: true,
                            element: <Navigate to="active" replace />,
                        },
                        {
                            path: "active",
                            element: <OneTimePaymentsActive />,
                        },
                        {
                            path: "inactive",
                            element: <OneTimePaymentsInactive />,
                        },
                    ],
                },
                {
                    path: "/pay",
                    handle: {
                        name: `Schedule payments`,
                        heading: "Schedule payments",

                        description: (
                            <>
                                <strong>Simplify your operations</strong>. Get
                                approval once to schedule payroll, loan
                                repayments, or any type of bill pay without
                                locking up funds while never having to reconcile
                                transactions and track down hashes again.
                            </>
                        ),
                        wallet: true,
                    },
                    element: (
                        <AccessControl rolesWithAccess={[UserRole.COMPANY]}>
                            <SchedulePayments />
                        </AccessControl>
                    ),
                },
                {
                    path: "/wallets",
                    handle: {
                        name: `Authorized wallets`,
                        heading: "Authorized outbound wallets",

                        wallet: true,
                    },
                    element: (
                        <AccessControl
                            rolesWithAccess={[
                                UserRole.COMPANY_READ,
                                UserRole.COMPANY,
                            ]}
                        >
                            <AuthorizedWallets />
                        </AccessControl>
                    ),
                },
                {
                    path: "/checkout-url",
                    handle: {
                        name: `Checkout link builder`,
                        heading: (
                            <>
                                <S.BackToTx />
                                Checkout link builder
                            </>
                        ),
                    },
                    element: (
                        <AccessControl
                            rolesWithAccess={[
                                UserRole.COMPANY_READ,
                                UserRole.COMPANY,
                            ]}
                        >
                            <CheckoutUrlBuilder />
                        </AccessControl>
                    ),
                },
                {
                    path: "/self-serve",
                    handle: {
                        name: `Receive payment`,
                        heading: (
                            <>
                                <S.BackToTx />
                                Receive payment
                            </>
                        ),
                    },
                    element: <SelfServe />,
                },
                {
                    path: "*",
                    element: <Navigate to="/" replace />,
                },
            ],
        },
    ],
    {
        future: {
            v7_normalizeFormMethod: true,
        },
    }
);

const CompanyPortal = () => {
    return (
        <>
            <QueryProvider>
                <EnsProvider>
                    <WalletInformationProvider>
                        <NotificationQueueProvider>
                            <GlobalStyle />
                            <WalletContextProvider>
                                <UserProvider loginType={LoginType.WEB2}>
                                    <RouterProvider
                                        router={router}
                                        fallbackElement={<Loading />}
                                    />
                                </UserProvider>
                            </WalletContextProvider>
                        </NotificationQueueProvider>
                    </WalletInformationProvider>
                </EnsProvider>
            </QueryProvider>
            <TawkMessengerReact
                propertyId="66a8d81a32dca6db2cb76cf0"
                widgetId="1i475m1ir"
            />
        </>
    );
};

export default CompanyPortal;
