import * as S from "./style";
import { useCallback, useEffect, useRef, useState } from "react";
import { formatDateTimeLocalForInput } from "utils/datetime";
import { toDollar } from "utils/financial";
import { useNotificationQueue } from "context/NotificationQueue";
import { useCheckoutData } from "checkout/context/CheckoutData";
import { useCheckoutForm } from "checkout/context/CheckoutForm";
import { ManagedDetailsProps } from "components/Details/ManagedDetails";
import { CheckoutPanel } from "checkout/components/CartAndForm";
import { CardSection } from "components/Card";
import PaymentDate from "checkout/components/PaymentDate";
import Notification, {
    NotificationContext,
    NotificationType,
} from "components/Notification";
import Button from "components/Button";
import { convertTokenToCents } from "utils/exchangeRates";
import { useWalletContext } from "context/WalletContext";
import { setSolTokenAllowance } from "utils/tokens";
import { subscribe } from "diagnostics_channel";

export interface StepThreeProps
    extends Omit<ManagedDetailsProps<CheckoutPanel.APPROVE>, "children"> {}

const StepThreeDetails = (props: StepThreeProps) => {
    // Hooks state and methods
    const { addNotification, removeNotification } = useNotificationQueue();
    const { isNetworkSetting, walletConnected } = useWalletContext();
    const { isInvoicedCheckout, isOneTimePayment } = useCheckoutData();
    const {
        token,
        contract,
        hasMinimumAllowance,
        sendAllowance,
        tokenMinimumAllowance,
        tokenSuggestedAllowance,
        currentAllowance,
        subscribing,
        subscribe,
    } = useCheckoutForm();

    // Local state
    const [allowanceIncreaseError, setAllowanceIncreaseError] =
        useState<boolean>(false);
    const [increasingAllowance, setIncreasingAllowance] =
        useState<boolean>(false);
    const [canSchedule, setCanSchedule] = useState(false);

    // Local component reference
    const paymentDateRef = useRef<PayDateFieldRef>(null);

    const submitAllowanceAndSubscribe = useCallback(async () => {
        if (!token || !contract) return;

        if (!hasMinimumAllowance) {
            const allowanceNotificationId = addNotification({
                msg: `Updating your ${token.symbol} allowance...`,
                type: NotificationType.WORKING,
            });
            setIncreasingAllowance(true);

            try {
                await sendAllowance(
                    tokenSuggestedAllowance.toFixed(token.decimals), // [ ] This adds extra decimals to the suggested allowance and may be lowering the value inadvertently
                    token,
                    contract
                );

                setAllowanceIncreaseError(false);
                setIncreasingAllowance(false);
                removeNotification(allowanceNotificationId);
            } catch (error) {
                setIncreasingAllowance(false);
                setAllowanceIncreaseError(true);
                removeNotification(allowanceNotificationId);
                console.error(`Error updating allowance: ${error}`);
                return;
            }
        }
        await subscribe(paymentDateRef.current?.value());
    }, [
        addNotification,
        contract,
        hasMinimumAllowance,
        removeNotification,
        sendAllowance,
        subscribe,
        token,
        tokenSuggestedAllowance,
    ]);

    useEffect(() => {
        // If the token or contract (network) changes, reset the notification
        setAllowanceIncreaseError(false);
    }, [token, contract]);

    useEffect(() => {
        if (!hasMinimumAllowance) return;

        // If you have the minimum allowance, remove the message
        setAllowanceIncreaseError(false);
    }, [hasMinimumAllowance]);

    const handleSubscribeClick = useCallback(async () => {
        await submitAllowanceAndSubscribe().catch((error) => {
            addNotification({ msg: error, type: NotificationType.ERROR });
        });
    }, [submitAllowanceAndSubscribe, addNotification]);

    const handlePaymentDateChange = useCallback(() => {
        // Update the button state based on validity, if the date value changes
        // If this is not an invoice checkout, always allow subscription
        setCanSchedule(
            !isInvoicedCheckout || !!paymentDateRef.current?.validate()
        );

        // Note paymentDateRef.current has to be a dependency here, or else its validity is not updated on load
    }, [isInvoicedCheckout, paymentDateRef.current]);

    useEffect(() => {
        // Confirm the button is in the right state at initial load
        handlePaymentDateChange();
    }, [handlePaymentDateChange]);

    const hasAllowance = currentAllowance !== 0;

    return (
        <S.StepThreeDetails {...props}>
            {isInvoicedCheckout && (
                <CardSection>
                    <PaymentDate
                        ref={paymentDateRef}
                        value={formatDateTimeLocalForInput(new Date(), {
                            dateOnly: true,
                        })}
                        onChange={handlePaymentDateChange}
                        disabled={subscribing || increasingAllowance}
                    />
                </CardSection>
            )}
            {token && (
                <>
                    <CardSection>
                        {allowanceIncreaseError && (
                            <Notification
                                key={"msg.id"}
                                message={{
                                    id: "formErrorDisplay",
                                    msg: (
                                        <>
                                            <strong>
                                                Insufficient authorization
                                            </strong>
                                            <p>
                                                Please set an authorization of
                                                at least{" "}
                                                {tokenMinimumAllowance.toFixed(
                                                    token.decimals
                                                )}{" "}
                                                {token.symbol} (
                                                {token.exchange.rate &&
                                                    toDollar(
                                                        convertTokenToCents(
                                                            tokenMinimumAllowance,
                                                            token.exchange.rate
                                                        )
                                                    )}
                                                )
                                            </p>
                                        </>
                                    ),
                                    type: NotificationType.ERROR,
                                    expires: false,
                                    context: NotificationContext.STATIC,
                                }}
                                removeNotification={false}
                            />
                        )}
                        {!allowanceIncreaseError && !hasAllowance && (
                            <p>
                                You'll sign a transaction to authorize Loop to
                                initiate{" "}
                                {isInvoicedCheckout || isOneTimePayment
                                    ? `a payment`
                                    : `payments`}{" "}
                                in {token.symbol}.
                            </p>
                        )}
                        {!allowanceIncreaseError &&
                            hasAllowance &&
                            hasMinimumAllowance && (
                                <>
                                    <p>
                                        You've authorized this company before
                                        and do not need to sign a transaction.
                                    </p>
                                </>
                            )}
                        {!allowanceIncreaseError &&
                            hasAllowance &&
                            !hasMinimumAllowance && (
                                <>
                                    <p>
                                        You'll sign a transaction to increase
                                        your authorization allowing Loop to
                                        initiate{" "}
                                        {isInvoicedCheckout || isOneTimePayment
                                            ? `a payment`
                                            : `payments`}{" "}
                                        in {token.symbol}.
                                    </p>
                                </>
                            )}
                    </CardSection>
                    <CardSection>
                        <Button
                            onClick={handleSubscribeClick}
                            disabled={!canSchedule || isNetworkSetting}
                            loading={subscribing || increasingAllowance}
                            full
                        >
                            {subscribing || increasingAllowance
                                ? "Confirming"
                                : "Confirm"}
                        </Button>
                    </CardSection>
                </>
            )}
        </S.StepThreeDetails>
    );
};

export default StepThreeDetails;
