import { useCallback } from "react";
import { Company } from "company/types";
import { useGetNetworks } from "./useGetNetworks";
import { useGetCompanyConfig } from "company/hooks/useGetCompanyConfig";
import { SmartContractType } from "types/common-enums";
import { useAvailableNetworks } from "./useAvailableNetworks";
import { useWalletInformationProvider } from "contexts/WalletInformationProvider";
import { ethers } from "ethers";

// This hooks will help us communicating with WalletInformationProvider,
// within a company-specific context
export const useBalanceAndAllowance = () => {
    const { networks, getNetworksIsLoading, getNetworksIsError } =
        useGetNetworks();

    const {
        config: { contracts },
        getCompanyConfigIsLoading,
        getCompanyConfigIsError,
    } = useGetCompanyConfig();

    const { getNetworkById } = useAvailableNetworks();

    const { loadWalletBalance, loadWalletAllowance } =
        useWalletInformationProvider();

    const loadBalanceAndAllowance = useCallback(
        async (
            walletAddress: string,
            tokenAddress: string,
            networkId: number
        ) => {
            if (
                getNetworksIsLoading ||
                getCompanyConfigIsLoading ||
                getNetworksIsError ||
                getCompanyConfigIsError
            ) {
                return;
            }

            const network = networks.find((n) => n.id === networkId);
            const selectedNetworkData =
                network && getNetworkById(network.hexId);
            const contract = contracts.find(
                (c: Company.Contract) =>
                    c.contractType === SmartContractType.VariableRate &&
                    c.networkId === networkId
            );

            if (!network || !contract || !selectedNetworkData) return;

            await loadWalletAllowance({
                walletAddress,
                tokenAddress,
                networkId,
                contractAddress: contract.address,
                rpcUrl: selectedNetworkData.rpcUrl,
            });

            await loadWalletBalance({
                walletAddress,
                tokenAddress,
                networkId,
                rpcUrl: selectedNetworkData.rpcUrl,
            });
        },
        [
            contracts,
            getCompanyConfigIsError,
            getCompanyConfigIsLoading,
            getNetworkById,
            getNetworksIsError,
            getNetworksIsLoading,
            loadWalletAllowance,
            loadWalletBalance,
            networks,
        ]
    );

    return { loadBalanceAndAllowance };
};
