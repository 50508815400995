import * as S from "./style";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthorizationTableRow, Customer } from "customer/types";
import { BlockExplorerEntityType } from "utils/urls";
import { formateDateForUrl } from "utils/datetime";
import { useCustomerData } from "customer/context/CustomerData";
import Table from "components/Table";
import Section from "components/Section";
import Anchor from "components/Anchor";
import ErrorMessage from "components/ErrorMessage";
import RefreshCounter, { CounterType } from "components/RefreshCounter";
import DownloadCsv from "components/DownloadCsv";
import { rowsToTableRecords } from "utils/tables";
import { toSafeStr, Transform } from "utils/strings";
import EmptyTableMessage from "components/EmptyTableMessage";
import { useWalletConnectedContext } from "context/WalletContext/WalletConnectedContext";

const tableHeadings: RowHeading<AuthorizationTableRow>[] = [
    { label: `Name`, field: `itemName`, sortable: true },
    { label: `Created`, field: `createdAt`, sortable: true },
    { label: `Frequency`, field: `frequency` },
    { label: `Amount`, field: `invoiced`, style: { textAlign: `right` } },
    {
        label: `Manage`,
        field: `status`,
        style: { shrink: true, textAlign: `center` },
    },
];

const csvHeadings: RowHeading<AuthorizationTableRow>[] = [
    { label: `Name`, field: `itemName` },
    { label: `Frequency`, field: `frequency` },
    { label: `Invoiced`, field: `invoicedAmt` },
    { label: `Token`, field: "invoicedToken" },
    { label: `Canceled`, field: `canceled` },
];

const sort = {
    column: 0,
    isAscending: true,
};

const AuthorizationDetails = () => {
    let { entityId } = useParams();
    const {
        entities,
        contracts,
        agreements,
        lastFetched,
        refetch,
        getAuthorizationRecordsByEntity,
    } = useCustomerData();

    const [agreementRows, setAgreementRows] = useState<AuthorizationTableRow[]>(
        []
    );
    const [agreementRecords, setAgreementRecords] = useState<Tuple[]>([]);

    const { networkConnected } = useWalletConnectedContext();

    // Top-level entity
    const entity: Customer.Entity | undefined = entities.find(
        ({ entityId: id }) => id === entityId
    );

    // The top-level contract
    const contract = contracts.find(({ owner }) => owner === entityId);

    useEffect(() => {
        if (!entityId) return;

        const rows = getAuthorizationRecordsByEntity(entityId);
        setAgreementRows(rows || []);
        if (!rows) return;

        setAgreementRecords(rowsToTableRecords(rows, tableHeadings));
    }, [agreements, getAuthorizationRecordsByEntity]);

    const handleRefresh = () => {
        if (!refetch) return;

        // Could replace tables with spinning loader animations
        refetch();
    };

    const logo = (
        <S.Logo>
            <S.NameOrLogo
                forwardedAs="div"
                entity={{
                    name: entity?.name,
                    logo: entity?.logoUrl,
                }}
                short={true}
            />
        </S.Logo>
    );

    return !entity ? (
        <Section>
            <ErrorMessage msg="This authorization does not exist">
                <p>
                    Head back to the{" "}
                    <Anchor href="/authorizations">authorization page</Anchor>{" "}
                    or{" "}
                    <Anchor
                        href={`mailto:${
                            import.meta.env.VITE_EMAIL_HELP
                        }?subject=Authorization%20${entityId}`}
                    >
                        contact us
                    </Anchor>{" "}
                    for assistance.
                </p>
            </ErrorMessage>
        </Section>
    ) : (
        <Section>
            <S.Entity>
                {entity.logoUrl &&
                    (entity.websiteUrl ? (
                        <Anchor href={entity.websiteUrl}>{logo}</Anchor>
                    ) : (
                        logo
                    ))}
                <S.CompanyInfo>
                    <h1>{entity.name}</h1>
                    {entity.websiteUrl && (
                        <Anchor
                            href={entity.websiteUrl}
                            icon
                            underlined={false}
                            block
                            nowrap
                        >
                            {entity.websiteUrl}
                        </Anchor>
                    )}
                    {contract?.address && (
                        <S.Contract>
                            <h4>Contract</h4>
                            <S.ContractAddr
                                address={contract.address}
                                networkId={networkConnected?.networkId}
                                type={BlockExplorerEntityType.Address}
                                underlined={false}
                                nowrap
                                icon
                            >
                                {contract.address}
                            </S.ContractAddr>
                        </S.Contract>
                    )}
                </S.CompanyInfo>
            </S.Entity>
            <S.Authorizations>
                <S.Filters>
                    <RefreshCounter
                        refreshedAt={lastFetched}
                        counter={CounterType.Running}
                        onRefresh={handleRefresh}
                    />
                    <S.Download>
                        <DownloadCsv
                            filename={`authorizations_${toSafeStr(entity.name, {
                                transform: Transform.LOWER,
                            })}_${formateDateForUrl(Date.now())}.csv`}
                            columns={csvHeadings}
                            rows={rowsToTableRecords(
                                agreementRows,
                                csvHeadings
                            )}
                        />
                    </S.Download>
                </S.Filters>
                <Table
                    data={{
                        headings: tableHeadings,
                        records: agreementRecords,
                    }}
                    defaultSort={sort}
                    ifNoRecords={
                        <EmptyTableMessage
                            title="No authorizations exist yet"
                            description={
                                <>
                                    Need help?{` `}
                                    <Anchor
                                        href={`mailto:${
                                            import.meta.env.VITE_EMAIL_HELP
                                        }`}
                                    >
                                        Contact us
                                    </Anchor>
                                    .
                                </>
                            }
                        />
                    }
                />
            </S.Authorizations>
        </Section>
    );
};

export default AuthorizationDetails;
