import { useCallback } from "react";
import { ethers, errors, BigNumber } from "ethers";
import {
    getBigNumberFromDecimal,
    strNumToDecimalPrecision,
} from "utils/financial";
import { tokenABI } from "default-variables";
import { useWalletContext } from "context/WalletContext";
import { EvmWalletSigner } from "types/common";

const swapType = {
    SINGLE: 0,
    MULTI: 1,
};

type EtherContractError = {
    reason: string;
    code: string;
};

type hardhatArtifact = {
    contractName: string;
    sourceName: string;
    abi: ethers.ContractInterface;
};

// [ ] This can be removed with "recurring" swap
const useLoopContract = () => {
    const { networkConnected, walletConnected, isWalletReady } =
        useWalletContext();

    const { getTokenAllowance } = useWalletContext();

    const getLoopContractAbi = async (): Promise<ethers.ContractInterface> => {
        if (!import.meta.env.VITE_LOCAL_RECURRING_BUY_CONTRACT_ADDRESS)
            return loopSmartContractABI;

        let localAbi: hardhatArtifact;
        try {
            localAbi = (await import(
                `../recurring/abi/RecurringSwapBase.json`
            ).then((module) => module.default)) as hardhatArtifact;
        } catch (error) {
            console.error(
                `There was a problem loading the dynamic ABI: ${error}`
            );
            return loopSmartContractABI;
        }

        return localAbi?.abi || loopSmartContractABI;
    };

    const getContract = useCallback(
        async ({
            tokenIn,
            abi,
        }: {
            tokenIn: string;
            abi: ethers.ContractInterface;
        }) => {
            let tokenContract;
            if (!walletConnected?.signer || !isWalletReady) {
                throw new Error(`Wallet is not ready for contract interaction`);
            }
            try {
                // TODO: Save to a useState to avoid multiple instantiation?
                tokenContract = new ethers.Contract(
                    tokenIn,
                    abi,
                    walletConnected.signer as EvmWalletSigner
                );

                // // @ts-ignore
                // tokenContract = contract.write;
            } catch (error) {
                throw new Error(
                    `There was a problem connecting to the token's smart contract`
                );
            }

            return tokenContract;
        },
        [walletConnected?.signer, isWalletReady]
    );

    const sendIncreaseAllowance = useCallback(
        async (
            contractAddress: string,
            tokenIn: string,
            amountIn: string, // String decimal value, not BigNumber
            decimalIn: number,
            skipIncreaseIfSufficientBalance: boolean = false,
            skipAwait: boolean = false,
            signedHandler: (() => void) | null = null,
            addToExistingAllowance: boolean = true
        ) => {
            const tokenContract = await getContract({
                tokenIn,
                abi: tokenABI,
            });

            const currentAllowance = BigNumber.from(
                await getTokenAllowance({
                    contractAddress,
                    tokenAddress: tokenIn,
                })
            );

            // Calculate total value of the automation
            const totalAutomationValue = ethers.utils.parseUnits(
                strNumToDecimalPrecision(
                    amountIn.replace(/[^\d.]/g, ""),
                    decimalIn
                ),
                decimalIn
            );

            if (
                skipIncreaseIfSufficientBalance &&
                totalAutomationValue.lte(currentAllowance)
            )
                return currentAllowance;

            // Increase the allowance
            let newAllowanceTx;

            try {
                newAllowanceTx = await tokenContract.approve(
                    contractAddress,
                    addToExistingAllowance
                        ? currentAllowance.add(totalAutomationValue)
                        : totalAutomationValue
                );
            } catch (error) {
                return Promise.reject(`Your allowance was not increased`);
            }

            if (signedHandler) signedHandler();
            if (skipAwait) return Promise.resolve(newAllowanceTx);

            // Get the receipt
            try {
                const receipt = await newAllowanceTx.wait();
                if (receipt.status === 0)
                    return Promise.reject(
                        `There was a problem registering your allowance increase`
                    );

                return Promise.resolve(receipt);
            } catch (error) {
                return Promise.reject(
                    `There was a problem registering your allowance increase`
                );
            }
        },
        [getContract, getTokenAllowance]
    );

    const sendRecurringSwapOrder = async (
        tokenIn: string,
        amountIn: string,
        decimalIn: number,
        tokenOut: string,
        frequency: string,
        slippage: number,
        txFee: number
    ) => {
        const timeoutDeadline = Math.floor(Date.now() / 1000) + 30 * 60; // 30 mins
        //const slippageAsPercentage = Number(slippage / 10 ** 6);

        // ! Revisit this to ensure no precision is lost when minAmountOut value is re-implemented
        // const minAmountOut = String(
        //     Number(buyAmount) * (1 - slippageAsPercentage)
        // );

        const orderData = ethers.utils.defaultAbiCoder.encode(
            [
                "address", // tokenIn;
                "address", // tokenOut;
                "uint24", // fee;
                "address", // recipient;
                "uint256", // deadline;
                "uint256", // amountIn;
                "uint256", // amountOutMinimum;
                "uint160", // sqrtPriceLimitX96;
            ],
            [
                tokenIn,
                tokenOut,
                txFee,
                walletConnected?.address,
                timeoutDeadline,
                getBigNumberFromDecimal(amountIn, decimalIn),
                0, //ethers.utils.parseUnits(minAmountOut, buyDecimal),
                0,
            ]
        );

        let loopContract, swapTransaction, receipt;

        const loopContractABI = await getLoopContractAbi();

        // Connect to Loop's smart contract
        try {
            loopContract = new ethers.Contract(
                networkConnected?.contractAddress || ``,
                loopContractABI,
                walletConnected?.signer as EvmWalletSigner
            );
        } catch (error) {
            throw new Error(
                `There was a problem connecting to the Loop Crypto smart contract`
            );
        }

        // Call the smart contract's swap function
        try {
            swapTransaction = await loopContract.createOrder(
                {
                    swapType: swapType.SINGLE,
                    frequency: Number(frequency), // in seconds
                    slippage: Number(slippage), // 0.1% = 0.001 * 1000000
                    data: orderData,
                },
                { gasLimit: 600000 }
            );
        } catch (error) {
            if (error instanceof Error && "code" in error) {
                const contractError = error as unknown as EtherContractError;
                if (contractError.code in errors) {
                    if (error.code === errors.ACTION_REJECTED) {
                        throw new Error(
                            `The smart contract swap was rejected by your wallet`
                        );
                    }
                    // TODO: Handle other errors?
                    // TODO: contractError.code note in errors?
                }
            }

            // Generic error
            throw new Error(
                `There was a problem executing the swap automation's contract`
            );
        }

        // Get the receipt
        try {
            receipt = await swapTransaction.wait();
            if (receipt.status === 0) {
                throw new Error(
                    `There was a problem registering your swap automation`
                );
            }
            return Promise.resolve(receipt);
        } catch (error) {
            throw new Error(
                `There was a problem registering your swap automation`
            );
        }
    };

    // Looks as if this is only relevant to cancelling recurring swaps, which is no longer supported
    const cancelAutomation = async (
        automationId: number,
        skipAwait = false
    ) => {
        // Load the ABI
        let loopAbi;
        try {
            loopAbi = await getLoopContractAbi();
        } catch (error) {
            throw new Error(`There was a problem loading Loop's contract ABI`);
        }

        // Ideal world we'd type our own contract
        let loopContract: ethers.Contract;
        try {
            loopContract = new ethers.Contract(
                networkConnected?.contractAddress || ``,
                loopAbi,
                walletConnected?.signer as EvmWalletSigner
            );
        } catch (error) {
            throw new Error(
                `There was a problem connecting to the smart contract`
            );
        }

        let transaction;
        try {
            transaction = await loopContract.cancelOrder(automationId, {
                gasLimit: 150000,
            });
        } catch (error) {
            throw new Error(`There was a problem executing the cancellation`);
        }

        if (skipAwait) return Promise.resolve(true);

        let receipt;
        try {
            receipt = await transaction.wait();
            if (receipt.status === 0) {
                throw new Error(
                    `There was a problem executing the cancellation`
                );
            }
        } catch (error) {
            throw new Error(`There was a problem executing the cancellation`);
        }

        return Promise.resolve(receipt);
    };

    return {
        sendIncreaseAllowance,
        sendRecurringSwapOrder,
        cancelAutomation,
    };
};

const loopSmartContractABI: ethers.ContractInterface = [
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "caller",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "orderId",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "enum IRecurringSwapBase.SwapType",
                name: "swapType",
                type: "uint8",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "frequency",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "slippage",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "address",
                name: "tokenIn",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "tokenOut",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amountIn",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "createdAt",
                type: "uint256",
            },
        ],
        name: "CreateOrder",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "caller",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "token",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amount",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "createdAt",
                type: "uint256",
            },
        ],
        name: "FeesWithdrawn",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "caller",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint8",
                name: "oldValue",
                type: "uint8",
            },
            {
                indexed: false,
                internalType: "uint8",
                name: "newValue",
                type: "uint8",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "createdAt",
                type: "uint256",
            },
        ],
        name: "MaxFeeWithdrawalBatchSizeUpdated",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "previousOwner",
                type: "address",
            },
            {
                indexed: true,
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "OwnershipTransferred",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "caller",
                type: "address",
            },
            {
                indexed: true,
                internalType: "uint256",
                name: "id",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amountIn",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "amountOut",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "minAmountOut",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "uint24",
                name: "fee",
                type: "uint24",
            },
        ],
        name: "ProcessOrder",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "uint256",
                name: "id",
                type: "uint256",
            },
            {
                indexed: false,
                internalType: "bytes",
                name: "err",
                type: "bytes",
            },
        ],
        name: "ProcessOrderFail",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "caller",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "newToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "chainlinkAggregator",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "createdAt",
                type: "uint256",
            },
        ],
        name: "TokenAdded",
        type: "event",
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: "address",
                name: "caller",
                type: "address",
            },
            {
                indexed: false,
                internalType: "address",
                name: "removedToken",
                type: "address",
            },
            {
                indexed: false,
                internalType: "uint256",
                name: "createdAt",
                type: "uint256",
            },
        ],
        name: "TokenRemoved",
        type: "event",
    },
    {
        inputs: [],
        name: "FEE_PRECISION",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "PROTOCOL_NAME",
        outputs: [
            {
                internalType: "string",
                name: "",
                type: "string",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "SLIPPAGE_PRECISION",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "USD_DECIMALS",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "WETH",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "acceptedTokensList",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "tokens_",
                type: "address[]",
            },
            {
                internalType: "address[]",
                name: "chainlinkAggregators_",
                type: "address[]",
            },
        ],
        name: "addTokens",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "orderId_",
                type: "uint256",
            },
        ],
        name: "cancelOrder",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: "enum IRecurringSwapBase.SwapType",
                        name: "swapType",
                        type: "uint8",
                    },
                    {
                        internalType: "uint32",
                        name: "frequency",
                        type: "uint32",
                    },
                    {
                        internalType: "uint24",
                        name: "slippage",
                        type: "uint24",
                    },
                    {
                        internalType: "bytes",
                        name: "data",
                        type: "bytes",
                    },
                ],
                internalType: "struct IRecurringSwapBase.CreateOrderParams",
                name: "order_",
                type: "tuple",
            },
        ],
        name: "createOrder",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "factory",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "fee",
        outputs: [
            {
                internalType: "uint24",
                name: "",
                type: "uint24",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getAcceptedTokensCount",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "getOrderCount",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "acceptedTokens_",
                type: "address[]",
            },
            {
                internalType: "address[]",
                name: "chainlinkAggregators_",
                type: "address[]",
            },
            {
                internalType: "uint24",
                name: "fee_",
                type: "uint24",
            },
            {
                internalType: "uint8",
                name: "maxBatchSize_",
                type: "uint8",
            },
            {
                internalType: "uint8",
                name: "maxFeeWithdrawalBatchSize_",
                type: "uint8",
            },
            {
                internalType: "address",
                name: "factory_",
                type: "address",
            },
            {
                internalType: "address",
                name: "WETH_",
                type: "address",
            },
        ],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "maxBatchSize",
        outputs: [
            {
                internalType: "uint16",
                name: "",
                type: "uint16",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "maxFeeWithdrawalBatchSize",
        outputs: [
            {
                internalType: "uint8",
                name: "",
                type: "uint8",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "orderIds",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "orderIdsByAddress",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        name: "orders",
        outputs: [
            {
                internalType: "address",
                name: "account",
                type: "address",
            },
            {
                internalType: "enum IRecurringSwapBase.SwapType",
                name: "swapType",
                type: "uint8",
            },
            {
                internalType: "uint32",
                name: "createdAt",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "processedAt",
                type: "uint32",
            },
            {
                internalType: "uint32",
                name: "frequency",
                type: "uint32",
            },
            {
                internalType: "uint24",
                name: "slippage",
                type: "uint24",
            },
            {
                internalType: "bytes",
                name: "data",
                type: "bytes",
            },
            {
                internalType: "uint256",
                name: "idx",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [],
        name: "owner",
        outputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256",
                name: "orderId_",
                type: "uint256",
            },
            {
                internalType: "address",
                name: "processor_",
                type: "address",
            },
        ],
        name: "processOrder",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint256[]",
                name: "orderIds_",
                type: "uint256[]",
            },
        ],
        name: "processOrders",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "processorBalances",
        outputs: [
            {
                internalType: "uint256",
                name: "",
                type: "uint256",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "tokens_",
                type: "address[]",
            },
        ],
        name: "removeTokens",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [],
        name: "renounceOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "",
                type: "address",
            },
        ],
        name: "tokenInfo",
        outputs: [
            {
                internalType: "uint8",
                name: "accepted",
                type: "uint8",
            },
            {
                internalType: "contract AggregatorV3Interface",
                name: "chainlinkAggregator",
                type: "address",
            },
        ],
        stateMutability: "view",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address",
                name: "newOwner",
                type: "address",
            },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint16",
                name: "batchSize_",
                type: "uint16",
            },
        ],
        name: "updateBatchSize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "uint8",
                name: "newBatchSize_",
                type: "uint8",
            },
        ],
        name: "updateMaxFeeWithdrawalBatchSize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
    {
        inputs: [
            {
                internalType: "address[]",
                name: "tokenAddresses_",
                type: "address[]",
            },
        ],
        name: "withdrawProcessorFees",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];

export { useLoopContract };
