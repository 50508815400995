import {
    forwardRef,
    Ref,
    useRef,
    useImperativeHandle,
    useCallback,
    ChangeEvent,
    useEffect,
} from "react";
import { useCheckoutForm } from "checkout/context/CheckoutForm";
import { useNetworkAndToken } from "checkout/context/NetworkAndToken";
import Field from "components/Field";
import Label from "components/Label";
import Select from "components/Select";
import { useWalletContext } from "context/WalletContext";

const SelectToken = forwardRef((_, ref: Ref<TokenFieldRef>) => {
    const { isNetworkSetting } = useWalletContext();
    const { tokenAddress, setTokenAddress, tokenOptions } =
        useNetworkAndToken();
    const { token } = useCheckoutForm();

    const tokenRef = useRef<HTMLSelectElement>(null);
    useImperativeHandle(ref, () => ({ value: tokenAddress }));

    const handleTokenChange = useCallback(
        ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
            setTokenAddress(value);
        },
        [setTokenAddress]
    );

    // When tokenOptions update, set the first token as the selected value
    useEffect(() => {
        setTokenAddress(tokenOptions[0]?.value || ``);
    }, [tokenOptions, setTokenAddress]);

    return (
        <Field>
            <Label htmlFor="tokenSelect">Token</Label>
            <Select
                innerRef={tokenRef}
                name="tokenSelect"
                value={tokenAddress || ``}
                onChange={handleTokenChange}
                options={tokenOptions}
                disabled={!token || isNetworkSetting}
                className={"fs-unmask"}
            />
        </Field>
    );
});

export default SelectToken;
