import * as S from "./style";
import {
    Dispatch,
    Ref,
    SetStateAction,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import { DynamicEmbeddedWidget } from "@dynamic-labs/sdk-react-core";
import { useWalletContext } from "context/WalletContext";
import Close from "components/icons/Close";
import { ButtonVariants } from "components/Button";

interface WalletModalProps {
    onConnectingChange?: Dispatch<SetStateAction<boolean>>;
}

export interface WalletModalRef {
    connecting: boolean;
    connect: () => void;
}

const WalletModal = forwardRef(
    ({ onConnectingChange }: WalletModalProps, ref: Ref<WalletModalRef>) => {
        const [isModalOpen, setIsModalOpen] = useState(false);
        const { isLoggedIn } = useWalletContext();

        // Prevent modal from closing when widget is clicked
        const handleWidgetClick = useCallback((event: any) => {
            event.stopPropagation();
        }, []);

        const handleCloseModal = useCallback((event: any) => {
            event.stopPropagation();
            setIsModalOpen(false);
        }, []);

        // Close the modal when connected for the first time
        useEffect(() => {
            if (isLoggedIn) setIsModalOpen(false);
        }, [isLoggedIn]);

        // Notify parent component of connecting state change
        useEffect(() => {
            if (onConnectingChange) onConnectingChange(isModalOpen);
        }, [isModalOpen, onConnectingChange]);

        // Expose functionality to parent component
        useImperativeHandle(
            ref,
            () => ({
                connecting: isModalOpen,
                connect: () => setIsModalOpen(true),
            }),
            [isModalOpen]
        );

        // Replace with this Modal component
        return isModalOpen ? (
            <S.WalletModal onClick={handleCloseModal} id="wallet-modal">
                <S.WidgetWrapper onClick={handleWidgetClick}>
                    <S.Close
                        variant={ButtonVariants.Anchor}
                        onClick={handleCloseModal}
                    >
                        <Close width="0.8rem" height="0.8rem" />
                    </S.Close>
                    <DynamicEmbeddedWidget
                        background="with-border"
                        style={{ width: `var(--dynamic-modal-width)` }}
                    />
                </S.WidgetWrapper>
            </S.WalletModal>
        ) : (
            <></>
        );
    }
);

export default WalletModal;
