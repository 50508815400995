import React from "react";
import { Company } from "company/types";
import Badge, { BadgeColors } from "components/Badge";
import CancelTransactionForm from "company/components/CancelTransactionForm";
import * as S from "./style";
import { CounterType } from "components/RefreshCounter";
import { useGetCompanyTransfers } from "company/hooks/useGetCompanyTransfers";
import { TransferStatus } from "types/common-enums";
import { useModal } from "context/ModalProvider";
import { useUser, UserRole } from "context/User";
import { ButtonVariants } from "components/Button";

interface TransactionHeaderProps {
    transaction: Company.Transaction;
}

const TransactionHeader: React.FunctionComponent<TransactionHeaderProps> = ({
    transaction,
}) => {
    const { hasRole } = useUser();
    const canManage = hasRole(UserRole.COMPANY);

    const canCancel =
        canManage && transaction.status === TransferStatus.Scheduled;

    const { openModal, closeModal } = useModal();
    const { getCompanyTransfersDataUpdatedAt, invalidateAllTransfersQueries } =
        useGetCompanyTransfers();

    const handleCreateItemRequestFormModal = () => {
        openModal(
            <CancelTransactionForm
                transferId={transaction.transferId}
                onClose={closeModal}
            />,
            `Cancel Transaction`
        );
    };

    type ExtendedStatus = TransferStatus | "Due";

    const transactionBadgeVariantForDisplay: {
        [key in ExtendedStatus]: string;
    } = {
        Scheduled: "blue",
        Succeeded: "green",
        Failed: "red",
        Cancelled: "orange",
        PastDue: "red",
        Pending: "yellow",
        Draft: "purple",
        Due: "red",
    };

    const transactionStatusesForDisplay: { [key in ExtendedStatus]: string } = {
        Scheduled: "Upcoming",
        Succeeded: "Confirmed",
        Failed: "Failed",
        Cancelled: "Canceled",
        PastDue: "Past Due",
        Pending: "Pending",
        Draft: "Draft",
        Due: "Due",
    };

    const badgeStatus =
        transaction.status === TransferStatus.Scheduled &&
        transaction.billDate * 1000 < Date.now()
            ? `Due`
            : transaction.status;

    const variant =
        transactionBadgeVariantForDisplay[badgeStatus as ExtendedStatus];
    const badgeContent =
        transactionStatusesForDisplay[badgeStatus as ExtendedStatus];

    return (
        <>
            <S.TransactionHeader>
                <S.DetailsRow>
                    <h2>{transaction.invoiceId}</h2>
                    <span>
                        <Badge variant={variant as BadgeColors}>
                            {badgeContent}
                        </Badge>
                    </span>
                    {canCancel && (
                        <S.CancelBtn
                            variant={ButtonVariants.Anchor}
                            onClick={() => handleCreateItemRequestFormModal()}
                        >
                            Cancel
                        </S.CancelBtn>
                    )}
                </S.DetailsRow>
                <S.Refresh
                    refreshedAt={getCompanyTransfersDataUpdatedAt}
                    counter={CounterType.Running}
                    onRefresh={() => invalidateAllTransfersQueries()}
                />
            </S.TransactionHeader>
        </>
    );
};

export default TransactionHeader;
