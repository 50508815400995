import { useEffect } from "react";
import { apiServerUrl } from "default-variables";
import { Customer } from "customer/types";
import { AgreementType } from "types/common-enums";
import { useNotificationQueue } from "context/NotificationQueue";
import { useSession } from "context/Session";
import { useFetch } from "hooks/useFetch";
import { NotificationType } from "components/Notification";
import { useWalletConnectedContext } from "context/WalletContext/WalletConnectedContext";

const useCancelAuthorizations = (
    agreements: Customer.Agreement[],
    setAgreements: React.Dispatch<React.SetStateAction<Customer.Agreement[]>>
) => {
    const { getSessionToken } = useSession();
    const { walletConnected } = useWalletConnectedContext();
    const address = walletConnected.proxyFor || walletConnected.address;

    const cancelOptions = {
        method: `PATCH`,
        headers: {
            "Content-Type": "application/json",
            Authorization: getSessionToken(),
            address: walletConnected.address,
        },
        body: JSON.stringify({
            agreementIds: [""],
        }),
    };

    const {
        data: cancelData,
        error: cancelError,
        refetch: cancelAgreementFetch,
    } = useFetch(
        `${apiServerUrl}/api/v1/customer/agreements/${address}`,
        cancelOptions,
        false
    );

    const { addNotification, removeNotification, clearAllNotifications } =
        useNotificationQueue();

    const cancelAuthorization = (id: string, name: string, entity: string) => {
        // Update options with id to cancel
        cancelOptions.body = JSON.stringify({
            agreementIds: [id],
        });

        addNotification({
            msg: `Cancelling ${name} from ${entity}`,
            type: NotificationType.WORKING,
            expires: false,
            customId: id,
        });

        cancelAgreementFetch(true, cancelOptions);
    };

    useEffect(() => {
        if (!cancelData) return;

        if (cancelData.agreements?.length < 1) {
            // Something went wrong.
            if (!cancelError) {
                clearAllNotifications();
                addNotification({
                    msg: `There was a problem cancelling an automation`,
                    type: NotificationType.ERROR,
                });
            }
            return;
        }

        const updatedAgreements: Customer.Agreement[] =
            cancelData.agreements.reduce(
                (
                    allAgreements: Customer.Agreement[],
                    {
                        agreementId,
                        cancelledAt,
                        status,
                    }: {
                        agreementId: string;
                        cancelledAt: number;
                        status: number;
                    }
                ) => {
                    removeNotification(agreementId);
                    addNotification({
                        msg: `Authorization cancelled`,
                        type: NotificationType.SUCCESS,
                    });

                    // Update local agreements data
                    const existingAgreement = allAgreements.find(
                        ({ id }) => id === agreementId
                    );
                    if (existingAgreement) {
                        existingAgreement.status = AgreementType[
                            status
                        ] as keyof AgreementType;
                        existingAgreement.endDate = cancelledAt;
                    }
                    return allAgreements;
                },
                [...agreements]
            );

        setAgreements(updatedAgreements);
    }, [cancelData]);

    useEffect(() => {
        if (!cancelError) return;

        /* 
        Problem here is that if multiple cancellations happened at the same time,
        we don't know which authorization caused this error message. Perhaps
        the move here is to limit users to one cancellation at a time (ie, one
        has to fail or succeed before another can be sent?) */
        clearAllNotifications();
        addNotification({ msg: cancelError, type: NotificationType.ERROR });
    }, [cancelError]);

    return { cancelAuthorization };
};

export { useCancelAuthorizations };
