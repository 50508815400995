import { GlobalStyle } from "global-style";
import * as S from "./style";
import {
    ScreenControllerProvider,
    useScreenController,
} from "recurring/context/ScreenController";
import { SwapFormProvider } from "recurring/context/SwapForm";
import WalletConnect from "components/WalletConnect";
import Warning from "components/Warning";
import { NotificationQueueProvider } from "context/NotificationQueue";
import WalletContextProvider from "context/WalletContext";

const RecurringPortal = () => {
    return (
        <NotificationQueueProvider>
            <GlobalStyle />
            <WalletContextProvider>
                <S.Layout>
                    <S.Header>
                        <S.Logo>
                            <a href="/">
                                <img
                                    src={`/img/loop-logo/loop-crypto-long.svg`}
                                    alt={`Loop Crypto`}
                                />
                            </a>
                        </S.Logo>
                        <S.Nav>
                            <S.Menu>
                                <li>
                                    <a
                                        href={
                                            import.meta.env.VITE_DASHBOARD_URL
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Portal
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href={import.meta.env.VITE_SWAP_FAQ}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        FAQ
                                    </a>
                                </li>
                            </S.Menu>
                        </S.Nav>
                        <WalletConnect />
                    </S.Header>
                    <S.Content>
                        <Warning color="#000000">
                            Use at your own risk. This contract is in beta and
                            has not yet been audited.
                        </Warning>
                        <SwapFormProvider>
                            <ScreenControllerProvider>
                                <AppScreenSwitch />
                            </ScreenControllerProvider>
                        </SwapFormProvider>
                    </S.Content>
                    <S.Nav>
                        <S.FooterMenu>
                            <li>
                                <a
                                    href={import.meta.env.VITE_TERMS_OF_SERVICE}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Terms of service
                                </a>
                            </li>
                            <li>
                                <a
                                    href={import.meta.env.VITE_PRIVACY}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Privacy policy
                                </a>
                            </li>
                        </S.FooterMenu>
                    </S.Nav>
                </S.Layout>
            </WalletContextProvider>
        </NotificationQueueProvider>
    );
};

const AppScreenSwitch = () => {
    const { component } = useScreenController();

    return component;
};

export default RecurringPortal;
