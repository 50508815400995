import { useCallback, useState } from "react";
import { AvailableNetwork } from "default-variables";
import { PrimaryWalletAccount } from "./useWalletConnected";
import { getEvmTokenBalance, getSolTokenBalance } from "utils/tokens";

export interface WalletTokenBalance {
    networkId: string; // hex
    wallet: string;
    token: string;
    symbol: string;
    balance: number | false;
    updatedAt: number;
}

export interface GetUpdatedTokenBalanceProps {
    symbol?: string | null; // [ ] Still necessary to support "null"?
    tokenAddress?: string | null; // [ ] Still necessary to support "null"?
    wallet?: string; // [ ] Can search other wallets, but must be on same chain and network
}

const useWalletBalance = (
    connectedWallet: PrimaryWalletAccount | null,
    network: AvailableNetwork | null
) => {
    const [_, setBalances] = useState<WalletTokenBalance[]>([]);

    // [ ] This is getting called in Checkout when the network switches, but before the signer has been changed yet, so the signer is on the wrong network
    // [ ] Rename symbol->tokenSymbol (or remove it), wallet->walletAddress, add networkId (which implies chain)
    const getUpdatedTokenBalance = useCallback(
        async ({
            symbol,
            tokenAddress,
            wallet = connectedWallet?.proxyFor || connectedWallet?.address,
        }: GetUpdatedTokenBalanceProps = {}): Promise<
            WalletTokenBalance["balance"]
        > => {
            if (!connectedWallet || !network || !wallet)
                throw new Error(`No wallet or network connected`);

            // Use the default token if no symbol is provided
            if (!symbol) symbol = network.token;

            let balance: number | false = false;
            try {
                if (connectedWallet.chain === "SOL") {
                    balance = await getSolTokenBalance({
                        tokenAddress,
                        walletAddress: wallet,
                        provider: connectedWallet.provider,
                    });
                } else {
                    balance = await getEvmTokenBalance({
                        tokenAddress,
                        walletAddress: wallet,
                        provider: connectedWallet.provider,
                    });
                }
            } catch (error) {
                throw new Error(
                    `There was a problem checking your wallet's ${symbol} balance: ${error}`
                );
            }

            // Use the wallet's address to represent the native token address
            if (!tokenAddress) tokenAddress = wallet;

            setBalances((prevBalances) => {
                const existingIndex = prevBalances.findIndex(
                    (existing) =>
                        existing.symbol === symbol &&
                        existing.token === tokenAddress &&
                        existing.wallet === wallet &&
                        existing.networkId === network.networkId
                );

                if (existingIndex !== -1) {
                    if (prevBalances[existingIndex].balance !== balance) {
                        const updatedBalances = [...prevBalances];
                        updatedBalances[existingIndex] = {
                            ...updatedBalances[existingIndex],
                            balance,
                            updatedAt: Date.now(),
                        };
                        return updatedBalances;
                    }
                    return prevBalances; // No change in balance
                }

                return [
                    ...prevBalances,
                    {
                        networkId: network.networkId,
                        wallet,
                        token: tokenAddress,
                        symbol,
                        balance,
                        updatedAt: Date.now(),
                    },
                ];
            });

            return Promise.resolve(balance);
        },
        [connectedWallet, network]
    );

    return { getUpdatedTokenBalance };
};

export default useWalletBalance;
