import {
    forwardRef,
    Ref,
    useRef,
    useImperativeHandle,
    useCallback,
    ChangeEvent,
    useState,
} from "react";
import { useNetworkAndToken } from "checkout/context/NetworkAndToken";
import Field from "components/Field";
import Label from "components/Label";
import { useWalletContext } from "context/WalletContext";
import { Blockchain } from "default-variables";
import SelectWithIcon from "components/SelectWithIcon";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";

const SelectNetwork = forwardRef((_, ref: Ref<NetworkFieldRef>) => {
    const { isNetworkSetting, walletConnected } = useWalletContext();
    const { networkHexId, updateNetworkHexId, networkOptions } =
        useNetworkAndToken();
    const [disabled, setDisabled] = useState<boolean>(false);
    const { getNetworkById } = useAvailableNetworks();

    const networkRef = useRef<HTMLSelectElement>(null);
    useImperativeHandle(ref, () => ({ value: networkHexId }));

    const handleNetworkChange = useCallback(
        ({ target: { value } }: ChangeEvent<HTMLSelectElement>) => {
            setDisabled(true);
            updateNetworkHexId(value).finally(() => setDisabled(false));
        },
        [updateNetworkHexId]
    );

    const areNetworksAvailable = networkOptions.some(
        ({ disabled }) => !disabled
    );

    const chainName =
        walletConnected?.chain && Blockchain[walletConnected.chain];

    const network = getNetworkById(networkHexId);

    // [ ] This functionality should be unified with the other instances of "Select a valid network"
    const placeholder = !networkHexId
        ? areNetworksAvailable
            ? {
                  value: ``,
                  label: `Select a valid network`,
              }
            : chainName
            ? {
                  value: ``,
                  label: `Switch to compatible wallet`,
              }
            : undefined
        : undefined;

    return (
        <Field>
            <Label htmlFor="networkSelect">Network</Label>
            <SelectWithIcon
                selectProps={{
                    innerRef: networkRef,
                    name: "networkSelect",
                    className: "fs-unmask",
                    value: networkHexId || ``,
                    onChange: handleNetworkChange,
                    placeholder: placeholder,
                    options: networkOptions,
                    disabled: disabled || isNetworkSetting,
                }}
                icon={
                    network
                        ? {
                              src: network.icon,
                              alt: `${network.label} logo`,
                          }
                        : undefined
                }
            />
        </Field>
    );
});

export default SelectNetwork;
