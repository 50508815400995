import { Box, Typography, Link, Card, CardContent } from "@mui/material";
import { BigNumber } from "ethers";
import styled from "styled-components";
import { apiServerUrl } from "default-variables";
import { useFetch } from "hooks/useFetch";
import { strDurationFromSeconds } from "utils/datetime";
import { formatCurrency } from "utils/financial";
import Copyable from "components/Copyable";
import { useSession } from "context/Session";
import { times100 } from "utils/numbers";
import { Contract } from "admin/types";
import { useWalletContext } from "context/WalletContext";

const Label = styled(Box)`
    display: inline-block;
    font-weight: bold;
    text-transform: capitalize;
`;

const Value = styled(Box)`
    display: inline-block;
`;

export interface SelectedContractProps {
    contract: Contract;
}

const SelectedContract = ({ contract }: SelectedContractProps) => {
    const { networkConnected } = useWalletContext();
    const { getSessionToken } = useSession();

    let networkId, contractPlansEndpoint;
    if (networkConnected) {
        networkId = BigNumber.from(networkConnected.networkId).toString();
        contractPlansEndpoint = `${apiServerUrl}/api/v1/subscription/${networkId}/${contract.contractAddress}/plans`;
    }

    const { data, loading }: any = useFetch(contractPlansEndpoint, {
        headers: {
            Authorization: getSessionToken(),
        },
    });

    if (loading) {
        return <></>;
    }

    const plans = data.plans;

    return (
        <>
            <Box mb={2}>
                <Typography variant="h2">Selected Contract</Typography>
            </Box>
            <Box mb={2}>
                <Typography>Company: {contract.entityName}</Typography>
            </Box>
            <Box display="flex" mb={2}>
                <Copyable>
                    Address:{" "}
                    <Link
                        href={`https://etherscan.io/address/${contract.contractAddress}`}
                        target="_blank"
                        color="inherit"
                    >
                        {contract.contractAddress}
                    </Link>
                </Copyable>
            </Box>
            <Box display="flex" flexDirection="column" gap={2}>
                {plans.map((plan: any) => {
                    return (
                        <Card key={plan.planName}>
                            <CardContent>
                                <Box>
                                    <Label mr={2}>Plan Name:</Label>
                                    <Value>{plan.planName}</Value>
                                </Box>
                                <Box>
                                    <Label mr={2}>Frequency:</Label>
                                    <Value>
                                        {strDurationFromSeconds(plan.frequency)}
                                    </Value>
                                </Box>
                                <Box>
                                    <Label mr={2}>Amount:</Label>
                                    <Value>{formatCurrency(plan.amount)}</Value>
                                </Box>
                                <Box>
                                    <Label mr={2}>Deposit Amount:</Label>
                                    <Value>
                                        {formatCurrency(plan.depositAmount)}
                                    </Value>
                                </Box>
                                <Box>
                                    <Label mr={2}>Fee (%):</Label>
                                    <Value>
                                        {times100(plan.feePercentage, 2)}%
                                    </Value>
                                </Box>
                            </CardContent>
                        </Card>
                    );
                })}
            </Box>
        </>
    );
};

export default SelectedContract;
