import { useRef } from "react";
import { useTokens } from "hooks/useTokens";
import { createContext, useContext, useEffect, useReducer } from "react";
import { orderFormDefaultValues as defaults } from "recurring/default-variables";
import { useWalletContext } from "context/WalletContext";

const SwapFormContext = createContext(null);

// State reducer actions
const stateUpdate = {
    tokens: `TOKENS`,
    prices: `PRICES`,
    exchange: `EXCHANGE`,
    frequency: `FREQUENCY`,
    slippage: `SLIPPAGE`,
    fee: `FEE`,
};

const SwapFormProvider = ({ children }) => {
    const { networkConnected, walletConnected, getUpdatedTokenBalance } =
        useWalletContext();
    const {
        getSellTokens,
        getBuyTokens,
        setupAllTokenPrices,
        getTokenOnNetwork,
    } = useTokens();

    const currentNetwork = useRef(null);
    const stateRef = useRef({
        tokens: {
            sell: [],
            buy: [],
        },
        prices: setupAllTokenPrices(),
        exchange: {
            sell: {
                amount: `0`,
                currency: null,
            },
            buy: {
                amount: `0`,
                currency: null,
            },
        },
        frequency: defaults.frequency,
        slippage: defaults.slippage,
        fee: {
            base: null,
            percent: null,
        },

        setExchange: (val) =>
            dispatch({ type: stateUpdate.exchange, value: val }),
        setPrices: (val) => dispatch({ type: stateUpdate.prices, value: val }),
        setFrequency: (val) =>
            dispatch({ type: stateUpdate.frequency, value: val }),
        setSlippage: (val) =>
            dispatch({ type: stateUpdate.slippage, value: val }),
        setFee: (val) => dispatch({ type: stateUpdate.fee, value: val }),
    });

    // Determine which data is to be updated, modify only that prop within the state
    const orderFormReducer = (_, action) => {
        switch (action.type) {
            case stateUpdate.tokens:
                stateRef.current.exchange = {
                    sell: {
                        amount: `0`,
                        currency: action.value.sell[0]?.symbol || ``,
                    },
                    buy: {
                        amount: `0`,
                        currency: action.value.buy[0]?.symbol || ``,
                    },
                };
                stateRef.current.tokens = structuredClone(action.value);
                break;
            case stateUpdate.prices:
                // Consider confirming this token/network pair doesn't exist already, in case multiple calls to getOrUpdateUsdPrice() were sent on the same render
                stateRef.current.prices = structuredClone(action.value);
                break;
            case stateUpdate.exchange:
                stateRef.current.exchange = structuredClone(action.value);
                break;
            case stateUpdate.frequency:
                stateRef.current.frequency = action.value;
                break;
            case stateUpdate.slippage:
                stateRef.current.slippage = action.value;
                break;
            case stateUpdate.fee:
                stateRef.current.fee = structuredClone(action.value);
                break;
            default:
                throw new Error(
                    `Woops, that's not a valid order form operation`
                );
        }

        return { ...stateRef.current };
    };
    const [orderData, dispatch] = useReducer(orderFormReducer, {
        ...stateRef.current,
    });

    // Network has changed, get new token list and fees
    // For the refactor: Consider moving this down to the exchange element
    useEffect(() => {
        if (currentNetwork.current === networkConnected.networkId) return;

        currentNetwork.current = networkConnected.networkId;

        let sellTokens, buyTokens;
        try {
            // Update tokens
            sellTokens = getSellTokens(networkConnected.networkId);
            buyTokens = getBuyTokens(
                networkConnected.networkId,
                sellTokens[0].symbol
            );
        } catch (error) {
            sellTokens = [];
            buyTokens = [];
        }

        dispatch({
            type: stateUpdate.tokens,
            value: {
                sell: sellTokens,
                buy: buyTokens,
            },
        });

        let baseFee, percentFee;
        try {
            baseFee = `$${networkConnected.recurringSwapContract.baseFee}`;
            percentFee = `${networkConnected.recurringSwapContract.percentFee}%`;
        } catch (error) {
            baseFee = null;
            percentFee = null;
        }
        dispatch({
            type: stateUpdate.fee,
            value: {
                baseFee: baseFee,
                percentFee: percentFee,
            },
        });
    }, [networkConnected.networkId]);

    // Update balances
    useEffect(() => {
        if (
            !orderData.tokens.sell.length ||
            !walletConnected ||
            !networkConnected
        )
            return;

        const updateBalanceForToken = (tokens) => {
            tokens.forEach(async ({ symbol }) => {
                const { tokenAddress, decimal } = getTokenOnNetwork(
                    networkConnected.networkId,
                    tokens[0].symbol
                );

                await getUpdatedTokenBalance({ symbol, tokenAddress });
            });
        };

        updateBalanceForToken(orderData.tokens.sell);
    }, [orderData.tokens.sell, walletConnected]);

    return (
        <SwapFormContext.Provider value={orderData}>
            {children}
        </SwapFormContext.Provider>
    );
};

const useSwapForm = () => {
    const context = useContext(SwapFormContext);
    if (context === undefined) {
        throw new Error(`useSwapForm() must be used within a SwapFormProvider`);
    }

    return context;
};

export { SwapFormProvider, useSwapForm };
